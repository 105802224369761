export const forwardOption = async (sessionId, userEmail, forward) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/forwarded/${userEmail}/${forward}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ sessionId }),
        }
      );
  
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  };