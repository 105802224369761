// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrape-input {
    border-top-left-radius: 5px !important; /* Keep the border radius on the right side */
    border-bottom-left-radius: 5px !important; /* Keep the border radius on the right side */
}`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/WebScraperCard.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC,EAAE,6CAA6C;IACrF,yCAAyC,EAAE,6CAA6C;AAC5F","sourcesContent":[".scrape-input {\r\n    border-top-left-radius: 5px !important; /* Keep the border radius on the right side */\r\n    border-bottom-left-radius: 5px !important; /* Keep the border radius on the right side */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
