import React, { useState, useEffect } from "react";
import { Divider } from "./Divider";
import "../css/Footer.css";

export const Footer = () => {
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        // User scrolled down
        setShowFooter(true);
      } else {
        // User scrolled up
        setShowFooter(false);
      }

      setLastScrollY(currentScrollY);
    };

    // Attach the event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <footer className={`footer-margin ${showFooter ? 'footer-visible' : ''}`}>
      <div className="container text-center">
        <p className="mt-2">
          &copy; 2023 WOLSKI IT ENTERPRISES All rights reserved.
        </p>
      </div>
    </footer>
  );
};
