import classnames from "classnames";
import React, { useState } from "react";
import ErrorFeedback from "../ErrorFeedback";
import "../components/css/InputForm.css";

const InputField = (props) => {
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  const {
    /** Input label */
    label,

    /** input name */
    name,

    /** input id, default is the name */
    id = name,

    /** State to show if field is not valid */
    isInvalid = false,

    /** State to show if field is not valid */
    isValid = false,

    /** error message if state is invalid */
    isInvalidFeedback,

    /** is field readonly */
    readonly = false,

    /** is field disabled */
    disabled = false,

    /** value of the input */
    value,

    /** placeholder for the input */
    placeholder,

    /** onChange function */
    onChange,

    /** onBlur function */
    onBlur = () => {},

    /** object that is injected into the input element */
    inputOptions,

    /** input type of element */
    type = "text",

    /** prefix  */
    prefix,

    /** postfix  */
    postfix,

    /** info icon */
    info,
    // prefix before input field
    prefixWrapper,

    // postfix after input field
    postfixWrapper,

    className,
    /**
     is an object given to ExtraInfoFloat. if undefined it is skipped
     example:
     {
      title:"Titel"
     }
     * */
    tooltip,
  } = props;

  const classList = classnames("fs-form-container", {
    "is-invalid": isInvalid,
    "is-valid": isValid,
    "is-hover": hover,
    "is-focus": focus,
    "is-disabled": disabled,
    "is-readonly": readonly,
  });

  return (
    <div className="fs-form-group fade-in-image">
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      )}
      <div className="row row--12">
        {prefixWrapper && (
          <div className="col-auto align-self-center">{prefixWrapper()}</div>
        )}

        <div className="col">
          <div
            className={classList}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div className="row no-gutters">
              {prefix && (
                <div className="col-auto align-self-center ml-2">{prefix}</div>
              )}
              <div className="col">
                <input
                  {...inputOptions}
                  type={type}
                  value={value}
                  id={id}
                  name={name}
                  readOnly={readonly}
                  disabled={disabled}
                  placeholder={placeholder}
                  className={`fs-form-control ${className}`}
                  onChange={onChange}
                  onFocus={() => setFocus(true)}
                  onBlur={(e) => {
                    onBlur(e);
                    setFocus(false);
                  }}
                />
              </div>

              {postfix && (
                <div className="col-auto align-self-center mr-2">{postfix}</div>
              )}
            </div>
          </div>
        </div>
        {postfixWrapper && (
          <div className="col-auto align-self-center">{postfixWrapper()}</div>
        )}
      </div>
      <ErrorFeedback
        isInvalid={isInvalid}
        isInvalidFeedback={isInvalidFeedback}
      />
    </div>
  );
};

export default InputField;
