import React, { useEffect } from "react";
import { PencilSquare, ThreeDots, Trash } from "react-bootstrap-icons";
import "../../css/PrivateRoutes/WebScrapeActionMenu.css";

const LiveApiActionMenu = ({ isOpen, toggleMenu, userId }) => {
  // Function to close the dropdown if clicked outside
  const closeMenu = (event) => {
    // Check if the target is not the menu or its descendants
    if (!event.target.closest(".action-menu-container")) {
      toggleMenu(null);
    }
  };

  useEffect(() => {
    if (isOpen) {
      // Add when the dropdown is open
      document.addEventListener("click", closeMenu);
    }
    return () => {
      // Remove when the dropdown is closed
      document.removeEventListener("click", closeMenu);
    };
  }, [isOpen]); // Only re-run the effect if isOpen changes

  return (
    <div className="action-menu-container">
      <ThreeDots
        onClick={() => toggleMenu(userId)}
        className="action-menu-icon"
      />
      {isOpen && (
        <div className="action-menu-dropdown">
          <button onClick={() => console.log("Edit action")}>
            <PencilSquare className="icon" /> Edit
          </button>
          <button onClick={() => console.log("Delete action")}>
            <Trash className="icon" /> Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default LiveApiActionMenu;
