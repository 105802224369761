// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-page-position {
margin-top: 20px;
margin-left: 1%;
}

/* Media query for screens less than 768 pixels wide */
@media (max-width: 768px) {
.edit-page-position {
margin-left: 0;
/* Smaller margin on smaller screens */
}
}

/* Media query for screens less than 480 pixels wide */
@media (max-width: 480px) {
.edit-page-position {
    margin-left: 0;
    /* Even smaller margin on phone screens */
}
}`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/EditBot.css"],"names":[],"mappings":"AAAA;AACA,gBAAgB;AAChB,eAAe;AACf;;AAEA,sDAAsD;AACtD;AACA;AACA,cAAc;AACd,sCAAsC;AACtC;AACA;;AAEA,sDAAsD;AACtD;AACA;IACI,cAAc;IACd,yCAAyC;AAC7C;AACA","sourcesContent":[".edit-page-position {\r\nmargin-top: 20px;\r\nmargin-left: 1%;\r\n}\r\n\r\n/* Media query for screens less than 768 pixels wide */\r\n@media (max-width: 768px) {\r\n.edit-page-position {\r\nmargin-left: 0;\r\n/* Smaller margin on smaller screens */\r\n}\r\n}\r\n\r\n/* Media query for screens less than 480 pixels wide */\r\n@media (max-width: 480px) {\r\n.edit-page-position {\r\n    margin-left: 0;\r\n    /* Even smaller margin on phone screens */\r\n}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
