import React, { useEffect, useState } from "react";
import moment from "moment";
import { WebScrapeIcon } from "../../assets/svgs/SvgIcons";
import "../../css/PrivateRoutes/IntelligenceCard.css";
import "../../css/PrivateRoutes/PromptsCard.css";
import "../../css/PrivateRoutes/WebScraperCard.css";
import { isValidURL } from "../../helpers/helpers";
import RobolButton from "../RobolButton";
import CardWrapper from "./CardWrapper";
import WebScrapeTable from "./WebScrapeTable";
import i18n from 'i18next';
import Tooltip from "../../helpers/Tooltip";
import { TooltipContent, TablesDescription } from "../../helpers/Enums";

const WebScraperCard = ({
  currentManualStep,
  initialValues,
  chatbotID,
  onWebsiteScrape,
  onWebsiteDelete,
}) => {
  const [url, setUrl] = useState(""); // State for the URL input field
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [webUrlList, setWebUrlList] = useState([]);
  const [isScraping, setIsScraping] = useState(false); // State for loading text
  const [progress, setProgress] = useState(0); // State for progress

  // Populate webUrlList with initialValues on component mount
  useEffect(() => {
    if (initialValues && initialValues.length > 0) {
      const formattedUrls = initialValues
        .map((url) => ({
          id: url.id,
          url: url.url,
          status: TablesDescription.WEB_STATUS(),
          timestamp: moment(url.date_scraped).format("YYYY-MM-DD HH:mm:ss"),
          created_by: url.user || "Unknown",
        }))
        .sort((a, b) => moment(b.timestamp).diff(moment(a.timestamp))); // Sort by timestamp from newest to oldest
      setWebUrlList(formattedUrls);
    } else {
      setWebUrlList([]);
    }
  }, [initialValues]);

  useEffect(() => {
    let timer;
    if (isScraping) {
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          const nextProgress = prevProgress + Math.random() * 10;
          if (nextProgress >= 100) {
            clearInterval(timer);
            setIsScraping(false);
            return 100;
          }
          return nextProgress;
        });
      }, 500);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isScraping]);

  const handleUrlChange = (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    setButtonDisabled(!isValidURL(newUrl));
  };

  const startWebScraping = async () => {
    console.log("Start web scraping for URL:", url);
    setProgress(0); // Reset progress
    setIsScraping(true);
    await onWebsiteScrape(url);
    setIsScraping(false);
    setUrl("");
  };

  const handleDelete = async (id) => {
    await onWebsiteDelete(id);
  };

  return (
    <>
      <div className="row">
        <div className="block-margin"></div>
        <div className="col pb-5">
          <CardWrapper className="mb-2" minHeight="50vh">
            <div className="row">
            <div className="col d-flex align-items-center">
                <h5 className="intelligence-cards-title d-flex align-items-center">
                  {currentManualStep}
                  <div className="ml-2">
                    <Tooltip toolTip={TooltipContent.WEB_SCRAPER()} />
                  </div>
                </h5>
              </div>
            </div>
            <hr className="black" />
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-8 mb-5">
                  <div className="prompts-card text-center mt-3">
                    <WebScrapeIcon width={35} color="#1E3050" />
                    {isScraping ? (
                      <div className="my-3">
                        <p>{i18n.t('chatbot_configuration.ExtraDescription.WEB_SCRAP_PROCES')}</p>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${progress}%` }}
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {Math.round(progress)}%
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <p className="my-3">
                        {i18n.t('chatbot_configuration.ExtraDescription.WEB_URL')}
                        </p>
                        {isMobile ? (
                          <>
                            <input
                              type="text"
                              className="form-control scrape-input"
                              placeholder="https://www.example.com"
                              aria-label="URL"
                              value={url}
                              onChange={handleUrlChange}
                            />
                            <RobolButton
                              theme="dark"
                              size="s"
                              onClick={startWebScraping}
                              disabled={isButtonDisabled}
                            >
                              Start web scraping
                            </RobolButton>
                          </>
                        ) : (
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control scrape-input"
                              placeholder="URL"
                              aria-label="URL"
                              aria-describedby="basic-addon2"
                              value={url}
                              onChange={handleUrlChange}
                            />
                            <RobolButton
                              theme="inputButton"
                              size="m"
                              onClick={startWebScraping}
                              disabled={isButtonDisabled}
                            >
                              Start web scraping
                            </RobolButton>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <WebScrapeTable scrapedWebsite={webUrlList} onDelete={handleDelete} />
          </CardWrapper>
        </div>
      </div>
    </>
  );
};

export default WebScraperCard;
