// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prompt-card-wrapper {
    background: var(--rbl-green-l);
    border-radius: 8px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
    padding: 20px 0;
    /* Apply padding to top and bottom only */
    width: auto;
    height: auto;
    overflow: hidden;
    position: relative;
    /* For absolute positioning of header elements */
}

.prompt-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    /* Apply horizontal margin to align with the padding of the wrapper */
}

.prompt-card-title {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
}

.prompt-card-actions {
    display: flex;
}

.prompt-card-action-btn {
    background: none;
    border: none;
    cursor: pointer;
}

.prompt-card-divider {
    border: 0;
    height: 1px;
    background: #000000;
    margin: 1.3rem 0;
}`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/PromptCardWrapper.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,kBAAkB;IAClB,6CAA6C;IAC7C,eAAe;IACf,yCAAyC;IACzC,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,gDAAgD;AACpD;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;IACd,qEAAqE;AACzE;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,WAAW;IACX,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".prompt-card-wrapper {\r\n    background: var(--rbl-green-l);\r\n    border-radius: 8px;\r\n    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);\r\n    padding: 20px 0;\r\n    /* Apply padding to top and bottom only */\r\n    width: auto;\r\n    height: auto;\r\n    overflow: hidden;\r\n    position: relative;\r\n    /* For absolute positioning of header elements */\r\n}\r\n\r\n.prompt-card-header {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    margin: 0 20px;\r\n    /* Apply horizontal margin to align with the padding of the wrapper */\r\n}\r\n\r\n.prompt-card-title {\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n    color: #000000;\r\n}\r\n\r\n.prompt-card-actions {\r\n    display: flex;\r\n}\r\n\r\n.prompt-card-action-btn {\r\n    background: none;\r\n    border: none;\r\n    cursor: pointer;\r\n}\r\n\r\n.prompt-card-divider {\r\n    border: 0;\r\n    height: 1px;\r\n    background: #000000;\r\n    margin: 1.3rem 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
