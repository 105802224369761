import React, { useState } from "react";
import StepsCardsWrapper from "../../components/PrivateRoutes/StepsCardsWrapper";
import { stepsEmployeeForm } from "../../helpers/jsonData";
import {
  AirplaneDepartureIcon,
  BusinessTimeIcon,
  InfoCircleIcon,
} from "../../assets/svgs/SvgIcons";

const StepCards = ({ steps, onClick, currentStep }) => {
  // Accept currentStep prop
  const [activeStep, setActiveStep] = useState(0);

  const handleStepClick = (index) => {
    setActiveStep(index);
    onClick(index); // Invoke the callback function passed from the parent component
  };

  return (
    <>
      {steps.map(
        (
          step,
          index // Use steps prop instead of stepsEmployeeForm
        ) => (
          <StepsCardsWrapper
            key={index}
            onClick={() => handleStepClick(index)}
            active={currentStep === index} // Use currentStep prop instead of activeStep
          >
            {index === 0 && (
              <span className="mr-1">
                <InfoCircleIcon
                  width={24}
                  height={24}
                  color={currentStep === index ? "white" : "#1E3050"} // Use currentStep prop instead of activeStep
                />
              </span>
            )}
            {index === 1 && (
              <span className="mr-1">
                <BusinessTimeIcon
                  width={24}
                  height={24}
                  color={currentStep === index ? "white" : "#1E3050"} // Use currentStep prop instead of activeStep
                />
              </span>
            )}
            {index === 2 && (
              <span className="mr-1">
                <AirplaneDepartureIcon
                  width={24}
                  height={24}
                  color={currentStep === index ? "white" : "#1E3050"} // Use currentStep prop instead of activeStep
                />
              </span>
            )}
            {step}
          </StepsCardsWrapper>
        )
      )}
    </>
  );
};

export default StepCards;
