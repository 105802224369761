// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 999 !important;
  color: white;
  padding: 0.5rem;
  background-color: #000000;
}

.footer-margin {
  /* Other styles... */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition */
}

.footer-visible {
  opacity: 1; /* Fully visible */
}
`, "",{"version":3,"sources":["webpack://./src/css/Footer.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,WAAW;EACX,kBAAkB;EAClB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;EACpB,UAAU,EAAE,qBAAqB;EACjC,6BAA6B,EAAE,sBAAsB;AACvD;;AAEA;EACE,UAAU,EAAE,kBAAkB;AAChC","sourcesContent":["footer {\r\n  position: fixed;\r\n  bottom: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  text-align: center;\r\n  z-index: 999 !important;\r\n  color: white;\r\n  padding: 0.5rem;\r\n  background-color: #000000;\r\n}\r\n\r\n.footer-margin {\r\n  /* Other styles... */\r\n  opacity: 0; /* Initially hidden */\r\n  transition: opacity 0.3s ease; /* Smooth transition */\r\n}\r\n\r\n.footer-visible {\r\n  opacity: 1; /* Fully visible */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
