import React, { useState } from "react";
import * as Yup from "yup";
import "../../css/PrivateRoutes/AddEmployee.css";
import { roleSelectbox } from "../../helpers/jsonData";
import NewUserForm from "../../formik/components/NewUserForm";
import i18n from 'i18next';

const AddEmployee = () => {
  const [activeSection, setActiveSection] = useState("personalDetails");

  const toggleSwitches = [{ name: "available", label: "Beschikbaar" }];

  const validationSchema = Yup.object().shape({
    // firstName: Yup.string().required("Voornaam verplicht"),
  });

  const fieldConfigurations = [
    {
      name: "role",
      type: "select",
      label: i18n.t('chatbot_configuration.Employee_creation_tab.authorization'),
      selectOptions: roleSelectbox.map(option => ({
        ...option,
        label: i18n.t(option.label),
      })),
    },
    {
      name: "function_title",
      type: "text",
      label: i18n.t('chatbot_configuration.Employee_creation_tab.job_title'),
    },
    {
      name: "firstName",
      type: "text",
      label: i18n.t('chatbot_configuration.Employee_creation_tab.name_employee'),
      placeholder: "Voornaam",
    },
    {
      name: "lastName",
      type: "text",
      label: i18n.t('chatbot_configuration.Employee_creation_tab.last_name'),
      placeholder: "Achternaam",
    },
    {
      name: "email",
      type: "email",
      label: i18n.t('chatbot_configuration.Employee_creation_tab.email'),
      placeholder: "Email",
    },
    {
      name: "phoneNumber",
      type: "text",
      label: i18n.t('chatbot_configuration.Employee_creation_tab.phone_number'),
      placeholder: "Telefoonnummer",
    },
    { name: "city", 
      type: "text", 
      label: i18n.t('chatbot_configuration.Employee_creation_tab.city'), 
      placeholder: "Stad" 
    },
    { name: "address", 
      type: "text", 
      label: i18n.t('chatbot_configuration.Employee_creation_tab.street'), 
      placeholder: "Adres" 
    },
    {
      name: "zipCode",
      type: "text",
      label: i18n.t('chatbot_configuration.Employee_creation_tab.zip_code'),
      placeholder: "Postcode",
    },
  ];

  const initialValues = {
    firstName: "",
    lastName: "",
    role: "",
    profileImage: "",
    email: "",
    phoneNumber: "",
    stateRegion: "",
    city: "",
    address: "",
    zipCode: "",
    function_title: "",
    vacations: [{ fromDate: "", toDate: "" }],
    workingSchedule: {
      monday: { startTime: "", endTime: "" },
      tuesday: { startTime: "", endTime: "" },
      wednesday: { startTime: "", endTime: "" },
      thursday: { startTime: "", endTime: "" },
      friday: { startTime: "", endTime: "" },
      saturday: { startTime: "", endTime: "" },
      sunday: { startTime: "", endTime: "" },
    },
  };

  const handleSubmit = (values) => {
    console.log(values);
  };

  return (
    <NewUserForm
      fields={fieldConfigurations}
      toggleSwitches={toggleSwitches}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      submitButtonText="Wijzigingen opslaan"
      sideSteps={true}
    />
  );
};

export default AddEmployee;
