import React from "react";
import { useField } from "formik";
import "./css/FormikTextArea.css";

export const FormikTextArea = ({ name, minHeight, width, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <div className="prompt-input form-group">
      <textarea
        {...field}
        {...props}
        className={`formik-field form-control ${
          meta.touched && meta.error ? "is-invalid" : ""
        }`}
        style={{
          minHeight: minHeight || "150px", // Default minHeight if not provided
          width: width || "100%", // Use provided width or default to 100%
        }}
      />
      {meta.touched && meta.error && (
        <div className="text-danger">{meta.error}</div>
      )}
    </div>
  );
};
