import { MagnifyingGlassIcon } from "../assets/svgs/SvgIcons";
import "../css/SearchBar.css";
const SearchBar = () => {
  return (
    <div className="search-container">
      <div className="search-bar">
        <MagnifyingGlassIcon color="#1E3050" width="16" height="16" />
        <input
          type="text"
          className="form-control search-input"
          placeholder="Zoeken.."
        />
      </div>
    </div>
  );
};

export default SearchBar;
