import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser?.email) return;

    const newSocket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET}?email=${currentUser?.email}`);

    newSocket.onopen = () => {
      console.log("WebSocket połączony");
      newSocket.send(JSON.stringify({ type: "subscribe", topic: "conversationData" }));
      // newSocket.send(JSON.stringify({ type: "fetchConversations" }));
    };

    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, [currentUser?.email]);

  return <WebSocketContext.Provider value={socket}>{children}</WebSocketContext.Provider>;
};
