import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CrossIcon } from "../../assets/svgs/SvgIcons";

const Toast = () => {
  useEffect(() => {}, []);

  return <ToastContainer position="top-center" />;
};

export const notify = (message) => {
  toast(message, {
    style: {
      backgroundColor: "#1E3050",
      color: "#fff",
      width: "100%",
      maxWidth: "600px",
      marginLeft: "auto",
      marginRight: "auto",
      position: "right",
      autoClose: 2000,
      hideProgressBar: false,
      newestOnTop: true,
      closeOnClick: true,
      rtl: false,
      closeButton: <CustomCloseButton />,
    },
  });
};

export const CustomCloseButton = ({ closeToast }) => (
  <CrossIcon color="#fff" width="10px" onClick={closeToast} />
);

export default Toast;
