import React from "react";
import "../../css/PrivateRoutes/FormAddEmployee.css";

const FormAddEmployee = ({ type, onClose }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted");
    onClose(); // Close the modal on successful submission
  };

  return (
    <>
      <div className="modal show d-block" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Medewerker toevoegen</h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
                {/* <div className="card-background" /> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                </div>
              </div>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder="Enter chatbot name"
                  />
                </div>
               
               
                <div className="form-group">
                  <label htmlFor="banner">Banner</label>
                  <input
                    type="file"
                    id="banner"
                    className="form-control-file"
                  />
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Add Employee
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show" onClick={onClose}></div>
    </>
  );
};

export default FormAddEmployee;
