// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.formik-label {
  margin-bottom: 5px; /* Adjust as needed for spacing between label and input */
}


/* YourCssFileName.css */
.formik-input {
  border: 1px solid #ccc;
  border-radius: 20px;
    padding: 10px 10px; /* Adjust padding as needed */
    font-size: 12px; /* Adjust font-size as needed */
    line-height: 1.5;
    width: 100%; /* Full width */
  }
  
  .formik-input:focus {
    border-color: #003366; /* Focus color */
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); /* Bootstrap's default focus shadow */
  }
  
  .form-group .text-danger {
    margin-top: .25rem;
    font-size: 0.7rem;
    position: absolute; /* Position error message correctly */
    bottom: -20px; /* Positioning below the input field */
    margin-left: 0.25rem;
  }
  
  /* Additional container styling */
  .form-group {
    position: relative; /* Needed for absolute positioning of the error message */
    margin-bottom: 15px; /* Space for error message */
  }
  
  .is-invalid {
    border-color: #dc3545; /* Bootstrap's default invalid border color */
  }
`, "",{"version":3,"sources":["webpack://./src/formik/components/css/FormikInput.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB,EAAE,yDAAyD;AAC/E;;;AAGA,wBAAwB;AACxB;EACE,sBAAsB;EACtB,mBAAmB;IACjB,kBAAkB,EAAE,6BAA6B;IACjD,eAAe,EAAE,+BAA+B;IAChD,gBAAgB;IAChB,WAAW,EAAE,eAAe;EAC9B;;EAEA;IACE,qBAAqB,EAAE,gBAAgB;IACvC,UAAU;IACV,4CAA4C,EAAE,qCAAqC;EACrF;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB,EAAE,qCAAqC;IACzD,aAAa,EAAE,sCAAsC;IACrD,oBAAoB;EACtB;;EAEA,iCAAiC;EACjC;IACE,kBAAkB,EAAE,yDAAyD;IAC7E,mBAAmB,EAAE,4BAA4B;EACnD;;EAEA;IACE,qBAAqB,EAAE,6CAA6C;EACtE","sourcesContent":[".form-group {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n}\r\n\r\n.formik-label {\r\n  margin-bottom: 5px; /* Adjust as needed for spacing between label and input */\r\n}\r\n\r\n\r\n/* YourCssFileName.css */\r\n.formik-input {\r\n  border: 1px solid #ccc;\r\n  border-radius: 20px;\r\n    padding: 10px 10px; /* Adjust padding as needed */\r\n    font-size: 12px; /* Adjust font-size as needed */\r\n    line-height: 1.5;\r\n    width: 100%; /* Full width */\r\n  }\r\n  \r\n  .formik-input:focus {\r\n    border-color: #003366; /* Focus color */\r\n    outline: 0;\r\n    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); /* Bootstrap's default focus shadow */\r\n  }\r\n  \r\n  .form-group .text-danger {\r\n    margin-top: .25rem;\r\n    font-size: 0.7rem;\r\n    position: absolute; /* Position error message correctly */\r\n    bottom: -20px; /* Positioning below the input field */\r\n    margin-left: 0.25rem;\r\n  }\r\n  \r\n  /* Additional container styling */\r\n  .form-group {\r\n    position: relative; /* Needed for absolute positioning of the error message */\r\n    margin-bottom: 15px; /* Space for error message */\r\n  }\r\n  \r\n  .is-invalid {\r\n    border-color: #dc3545; /* Bootstrap's default invalid border color */\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
