import i18n from 'i18next';


export const SidebarTitles = {
  BACK: () => i18n.t('chatbot_configuration.SidebarTitles.BACK'),
  INTELLIGENCE: () => i18n.t('chatbot_configuration.SidebarTitles.INTELLIGENCE'),
  KNOWLEDGE: () => i18n.t('chatbot_configuration.SidebarTitles.KNOWLEDGE'),
  CONTEXT: () => i18n.t('chatbot_configuration.SidebarTitles.CONTEXT'),
  GPT_MODEL: () => i18n.t('chatbot_configuration.SidebarTitles.GPT_MODEL'),
  DESIGN: () => i18n.t('chatbot_configuration.SidebarTitles.DESIGN'),
  ACTIVATION: () => i18n.t('chatbot_configuration.SidebarTitles.ACTIVATION'),
  TEST: () => i18n.t('chatbot_configuration.SidebarTitles.TEST'),
  ACTIVATE: () => i18n.t('chatbot_configuration.SidebarTitles.ACTIVATE'),
  FAQ: () => i18n.t('chatbot_configuration.SidebarTitles.FAQ'),
};


export const SidebarSubitems = {
  PERSONALITY: () => i18n.t('chatbot_configuration.SidebarSubitems.PERSONALITY'),
  PERSONALITY_PLACEHOLDER: () => i18n.t('chatbot_configuration.SidebarSubitems.PERSONALITY_PLACEHOLDER'),
  COMPANY: () => i18n.t('chatbot_configuration.SidebarSubitems.COMPANY'),
  COMPANY_PLACEHOLDER: () => i18n.t('chatbot_configuration.SidebarSubitems.COMPANY_PLACEHOLDER'),
  RESTRICTIONS: () => i18n.t('chatbot_configuration.SidebarSubitems.RESTRICTIONS'),
  RESTRICTIONS_PLACEHOLDER: () => i18n.t('chatbot_configuration.SidebarSubitems.RESTRICTIONS_PLACEHOLDER'),
  RESTRICTIONS_BTN: () => i18n.t('chatbot_configuration.SidebarSubitems.RESTRICTIONS_BTN'),
  RESTRICTIONS_BTN_EDIT: () => i18n.t('chatbot_configuration.SidebarSubitems.RESTRICTIONS_BTN_EDIT'),
  RESTRICTIONS_BTN_DELETE: () => i18n.t('chatbot_configuration.SidebarSubitems.RESTRICTIONS_BTN_DELETE'),
  PROMPTS: () => i18n.t('chatbot_configuration.SidebarSubitems.PROMPTS'),
  PROMPTS_CATEGORY_BTN_CREATE: () => i18n.t('chatbot_configuration.SidebarSubitems.PROMPTS_CATEGORY_BTN_CREATE'),
  PROMPTS_CATEGORY_BTN_CANCEL: () => i18n.t('chatbot_configuration.SidebarSubitems.PROMPTS_CATEGORY_BTN_CANCEL'),
  PROMPTS_DESCRIPTION: () => i18n.t('chatbot_configuration.SidebarSubitems.PROMPTS_DESCRIPTION'),
  WEB_SCRAPERS: () => i18n.t('chatbot_configuration.SidebarSubitems.WEB_SCRAPERS'),
  DOCUMENT_SCRAPERS: () => i18n.t('chatbot_configuration.SidebarSubitems.DOCUMENT_SCRAPERS'),
  AVATARS: () => i18n.t('chatbot_configuration.SidebarSubitems.AVATARS'),
  COLORS: () => i18n.t('chatbot_configuration.SidebarSubitems.COLORS'),
  INTERACTIVE_TEST: () => i18n.t('chatbot_configuration.SidebarSubitems.INTERACTIVE_TEST'),
  ACTIVATE_CHATBOT: () => i18n.t('chatbot_configuration.SidebarSubitems.ACTIVATE_CHATBOT'),
  LAYOUT_CONFIGURATION: () => i18n.t('chatbot_configuration.SidebarSubitems.LAYOUT_CONFIGURATION'),
  FAQ_Q1: () => i18n.t('chatbot_configuration.SidebarSubitems.FAQ_Q1'),
  FAQ_Q2: () => i18n.t('chatbot_configuration.SidebarSubitems.FAQ_Q2'),
  TEST: () => i18n.t('chatbot_configuration.SidebarSubitems.TEST'),
};

