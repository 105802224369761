import React, { useEffect, useState } from "react";
import "../css/Messageshub.css";
import { useAuth } from "../context/AuthContext";

const Messageshub = ({ chatBotID }) => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [userMessage, setUserMessage] = useState("");
  const { currentUser } = useAuth();

  console.log("currentUser", currentUser?.email);
  useEffect(() => {
    const socket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET}?email=${currentUser?.email}`);

    const initializeWebSocket = () => {
      socket.onopen = () => {
        socket.send(JSON.stringify({ type: "subscribe", topic: "conversation_data" }));
        socket.send(JSON.stringify({ type: "fetchConversations" }));
      };

      socket.onmessage = (event) => {
        try {
          const receivedData = JSON.parse(event.data);

          if (receivedData.type === "conversationData" && Array.isArray(receivedData.data)) {
            // Sort conversations by 'started_at' date, most recent first
            const sortedConversations = receivedData.data.sort(
              (a, b) => new Date(b.started_at) - new Date(a.started_at),
            );
            setConversations(sortedConversations);

            // If there is a selected conversation, update it with the new data
            if (selectedConversation) {
              const updatedSelectedConversation = sortedConversations.find(
                (convo) => convo.sessionId === selectedConversation.sessionId,
              );
              if (updatedSelectedConversation) {
                setSelectedConversation(updatedSelectedConversation);
              }
            }
          } else {
            console.error("Received data is not in the expected format");
          }
        } catch (error) {
          console.error("Error parsing WebSocket data:", error);
        }
      };
    };

    initializeWebSocket();

    return () => {
      socket.close();
    };
  }, [currentUser?.email]);

  useEffect(() => {
    if (selectedConversation) {
      // Find the updated conversation based on the selectedConversation's sessionId
      const updatedConversation = conversations.find((c) => c.sessionId === selectedConversation.sessionId);

      // If the conversation is found and it has been updated
      if (updatedConversation && updatedConversation.conversation_data !== selectedConversation.conversation_data) {
        setSelectedConversation(updatedConversation);
      }
    }
  }, [conversations]); // Add conversations as a dependency to this effect

  const selectConversation = (conversation) => {
    setSelectedConversation(conversation);
  };

  const sendMessage = async () => {
    if (!selectedConversation || !userMessage) {
      return;
    }

    // Przygotowanie nowej wiadomości użytkownika
    const newMessage = {
      role: "remote-worker",
      content: userMessage,
    };

    // Dodanie nowej wiadomości do istniejących danych konwersacji
    const updatedConversationData = [...selectedConversation.conversation_data, newMessage];

    // Aktualizacja konwersacji w backendzie
    await updateConversation(selectedConversation.sessionId, updatedConversationData);

    // Czyszczenie pola tekstowego po wysłaniu wiadomości
    setUserMessage("");
  };

  const updateConversation = async (sessionId, updatedConversationData) => {
    try {
      await fetch(`${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/msg/api/updateConversation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessionId: sessionId,
          conversationData: updatedConversationData,
        }),
      });
      // Handle response if needed
    } catch (error) {
      console.error("Error updating conversation:", error);
    }
  };

  return (
    <div className='my-chatbot'>
      <div className='container-fluid h-100'>
        <div className='row h-100'>
          <div className='col-4 h-100'>
            <div className='list-group h-100' style={{ overflowY: "auto" }}>
              {conversations.map((conversation, index) => (
                <button
                  key={index}
                  className={`list-group-item list-group-item-action ${
                    selectedConversation?.id === conversation.id ? "active" : ""
                  }`}
                  onClick={() => selectConversation(conversation)}
                >
                  Session ID: {conversation.sessionId}
                  <br />
                  Started: {new Date(conversation.started_at).toLocaleString()}
                  <br />
                  Messages: {conversation.messages_count}
                </button>
              ))}
            </div>
          </div>
          <div className='col-8 h-100'>
            <div className='Messenger_content'>
              {selectedConversation ? (
                <div className='Messages_list'>
                  <div className='text-center'>
                    <p>LIVE GESPREK</p>
                    <p>Gestart op: {new Date(selectedConversation.started_at).toLocaleString()}</p>
                  </div>
                  {selectedConversation.conversation_data.map((message, index) => (
                    <div
                      key={index}
                      className={`message-bubble ${message.role === "user" ? "user-message" : "bot-message"}`}
                    >
                      {message.role === "user" && <span className='userResponseText'>{message.content}</span>}
                      {message.role !== "user" && (
                        <>
                          <span className='responsText'>{message.content}</span>
                        </>
                      )}
                    </div>
                  ))}

                  {/* Dodaj pole do wprowadzania tekstu i przycisk "Wyślij" tutaj */}
                  <div className='text-center'>
                    <input
                      type='text'
                      placeholder='Wpisz wiadomość...'
                      value={userMessage}
                      onChange={(e) => setUserMessage(e.target.value)}
                    />
                    <button onClick={sendMessage}>Wyślij</button>
                  </div>
                </div>
              ) : (
                <div className='text-center'>
                  <p>No conversation selected</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messageshub;
