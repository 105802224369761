import { postToAPI } from "./ApiGeneral";

export const createChat = async (token, employeeName) => {
    try {
      const response = await postToAPI(
        `/api/v1/config/api/createChat?employeeName=${employeeName}`,
        token
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to create chat");
      }
  
      return await response.json();
    } catch (error) {
      throw new Error(error.message);
    }
  };