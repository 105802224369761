import React, { useEffect, useState } from "react";
import "../../css/PrivateRoutes/ChatbotIntern.css";
import {
  CrossIcon,
  MinimizeIcon,
  RobolLogo,
  SendIcon,
} from "../../assets/svgs/SvgIcons";

import defaultAvatarChatbot from "../../assets/defaultAvatarChatbot.png";
import defaultAvatarChatbox from "../../assets/whitebluelogo.png";
import "../../css/PrivateRoutes/ChatbotInteractiveTester.css";
import i18n from 'i18next';

const ChatbotIntern = ({ chatBotID, copyMessageToAiAssistent }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [triggeredAnswer, setTriggeredAnswer] = useState("");
  const [user, setUser] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [employeeName, setEmployeeName] = useState("Medewerker");
  const [department, setDepartment] = useState("");
  const [API_KEY, setAPI_KEY] = useState("");
  const [gpt_model, setGptModel] = useState("");

  const chatBotAvatar = defaultAvatarChatbot;
  const chatBoxAvatar = defaultAvatarChatbox;

  const color_primary = "#009933";
  const color_secondary = "#009933";
  const color_tertiary = "#003366";
  const color_button = "#009933";

  const [systemMessage, setSystemMessage] = useState({
    role: "system",
    content:
      user && user.length > 0
        ? user[0]?.content
        : "You are a worker and you are nice.",
  });

  useEffect(() => {
    if (copyMessageToAiAssistent && copyMessageToAiAssistent !== null) {
      // If the prop is available and not null, send it to the bot
      const userMessage = {
        message: `De gebruiker heeft de volgende vraag gesteld, kan je mij helpen met beantwoorden van deze vraag?: ${copyMessageToAiAssistent}`,
        sender: "user",
      };
      setMessages([...messages, userMessage]);
      setMessage("");
      setIsTyping(true);
      processMessageToChatGPT([...messages, userMessage]);
      setIsTyping(false);
    }
  }, [copyMessageToAiAssistent]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/configBychatBotID/${chatBotID}/1707062909972_recw40lrv` //default sessionId for dev
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setEmployeeName(data[0]?.employeeName || "Medewerker");
        setAPI_KEY(data[0]?.apiKey || "");
        setDepartment(data[0]?.department || "");
        setGptModel(data[0]?.gpt_model || "");

        // If avatar is a base64 string, you can set it directly
        const avatarBase64 = data[0]?.avatar; // This should already be a base64 string
        if (avatarBase64) {
          setAvatarUrl(avatarBase64); // setAvatarUrl should be set to handle a base64 string
        }

        // If avatar is a base64 string, you can set it directly
        const companyIconBase64 = data[0]?.company_icon; // This should already be a base64 string
        if (companyIconBase64) {
          setLogoUrl(companyIconBase64); // setAvatarUrl should be set to handle a base64 string
        }

        setUser(data);
        const welcomeMessage = data[0]?.welcomeMessage;
        setSystemMessage({
          role: "system",
          content: data[0]?.content,
        });
        setMessages((prevMessages) => [
          {
            ...prevMessages[0],
            message: welcomeMessage,
          },
          ...prevMessages.slice(1),
        ]);
      })
      .catch((error) => {
        console.error("Error fetching user info:", error);
        setUser([]);
        setMessages([
          {
            message:
              "Er gaat momenteel iets mis met mijn server. Probeer straks opnieuw.",
            sender: "bot",
          },
        ]);
      });
  }, []);

  useEffect(() => {
    if (messages?.length > 1) {
      const filteredMessages = messages.filter((msg) => msg.sender === "user");

      if (filteredMessages.length > 0) {
        const lastMessage =
          filteredMessages[filteredMessages.length - 1].message;

        const sentenceContainsKeyword = (sentence) => {
          const lowercaseSentence = sentence.toLowerCase();
          return keywords.some((keyword) =>
            lowercaseSentence.includes(keyword)
          );
        };

        if (
          sentenceContainsKeyword(lastMessage) &&
          triggeredAnswer !== lastMessage
        ) {
          setTriggeredAnswer(lastMessage);
        } else {
          setTriggeredAnswer("");
        }
      }
    }
  }, [messages]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--primary-color",
      color_primary
    );

    document.documentElement.style.setProperty(
      "--secondary-color",
      color_secondary
    );

    document.documentElement.style.setProperty(
      "--tertiary-color",
      color_tertiary
    );
    document.documentElement.style.setProperty("--color-button", color_button);
  }, [color_primary, color_secondary, color_tertiary, color_button]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (message.trim() === "") return;

    const userMessage = {
      message: message,
      sender: "user",
    };
    setMessages([...messages, userMessage]);
    setMessage("");

    const systemMessage = {
      role: "system",
      content: "Your system message content here",
    };
    const employeeName = "Your Employee Name";

    setIsTyping(true);
    await processMessageToChatGPT(
      [...messages, userMessage],
      systemMessage,
      employeeName
    );
    setIsTyping(false);
  };

  const processMessageToChatGPT = async (chatMessages) => {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message };
    });

    const apiRequestBody = {
      model: gpt_model,
      messages: [systemMessage, ...apiMessages],
    };

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + API_KEY,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiRequestBody),
          max_tokens: 10,
        }
      );

      if (!response.ok) {
        throw new Error("API call failed.");
      }

      const data = await response.json();

      if (data.choices && data.choices.length > 0) {
        const chatGptMessage =
          data.choices[0]?.message?.content ||
          "I'm sorry, I couldn't generate a response.";

        const newMessages = [
          ...chatMessages,
          {
            message: chatGptMessage,
            sender: employeeName,
          },
        ];

        setMessages(newMessages);
      }
    } catch (error) {
      console.error("Error in processing message:", error);
    }

    setIsTyping(false);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <div className="chatbot-container robolCss">
      <div className="showBotSubject">
        <div className="Layout Layout-open Layout-expand Layout-right">
          <div className="Messenger_messenger">
            <div className="p-3 header-content d-flex justify-content-between">
              <div className="header-left">
                <span className="employee-name">{i18n.t('chatbot_statistics.personal_assistant')}</span>
              </div>
              <div className="header-right ml-auto">
                {chatBoxAvatar ? (
                  <img
                    src={chatBoxAvatar}
                    className="float-right" // Bootstrap class to float the image to the right
                    width="35"
                    height="35"
                    alt="companyLogo"
                  />
                ) : (
                  <RobolLogo />
                )}
              </div>
            </div>
            <div className="Messenger_content">
              <div className="Messages">
                <div className="Messages_list">
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`msg ${msg.sender === "user" ? "user" : ""}`}
                    >
                      <span className="avtr">
                        {msg.sender === "user" ? (
                          <></>
                        ) : (
                          <div className="avatar-container">
                            <img
                              src={chatBotAvatar}
                              alt="defaultAvatarChatbot"
                            />
                          </div>
                        )}
                      </span>

                      <span
                        className={`${
                          msg.sender === "user"
                            ? "userResponseText"
                            : "responsText"
                        }`}
                      >
                        {msg.message}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <form id="messenger">
                <div className="Input Input-blank">
                  <input
                    name="msg"
                    className="Input_field"
                    placeholder={i18n.t('chatbot_statistics.write_msg')}
                    value={message}
                    onChange={handleMessageChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleSubmit(e);
                      }
                    }}
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(e);
                    }}
                    type="submit"
                    className="Input_button Input_button-send"
                  >
                    <div className="Icon">
                      <SendIcon />
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatbotIntern;
