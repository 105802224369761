import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Configuration,
  HomeSolidIcon,
  MessageEnvelopIcon,
  SignOutIcon,
  TargetsIcon,
  ToolsCircleIcon,
} from "../assets/svgs/SvgIcons";

import "../css/PrivateRoutes/PrivateNavbar.css";
import ConfirmationModal from "./ConfirmationModal";
import EditBotSidebarSteps from "./EditBotSidebarSteps";
import { logout } from "./FirebaseMethods";
import { UserRoles } from "../helpers/Enums";

const PrivateNavbar = ({ role }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isEditingBotSidebar, setIsEditingBotSidebar] = useState(false);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(null);
  const [leftMenuStyle, setLeftMenuStyle] = useState(false);
  const [isScreenWidthSmall, setIsScreenWidthSmall] = useState(
    window.innerWidth < 768
  );
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const isEditBotPage = location.pathname.startsWith(
    "/dashboard/admin/bot/edit/"
  );

  const isDashboardPage = location.pathname === "/dashboard";

  const handleToggleLeftMenu = (boolean) => {
    setLeftMenuStyle(boolean);
  };

  useEffect(() => {
    // Function to update state based on window width
    function handleResize() {
      setIsScreenWidthSmall(window.innerWidth < 768);
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    // Check if the current pathname matches the condition to show the EditBotSidebarSteps
    const onEditPage = location.pathname.startsWith(
      "/dashboard/admin/bot/edit/"
    );
    if (onEditPage) {
      setIsSidebarExpanded(false);
    }
    setIsEditingBotSidebar(onEditPage);

    // If we are on the edit page, ensure the sidebar is not expanded by default
  }, [location]);

  const [labelInfo, setLabelInfo] = useState({
    text: "",
    position: { top: 0, left: 0 },
  });

  useEffect(() => {
    handleItemClick("");
  }, [isEditBotPage]);

  useEffect(() => {
    handleToggleLeftMenu(true);
  }, [!isEditBotPage]);

  const collapseSidebar = () => {
    setIsSidebarExpanded(false);
  };

  const handleLogout = async () => {
    await logout();
    setShowConfirmModal(false);
    collapseSidebar();
  };

  const handleCancelLogout = () => {
    setShowConfirmModal(false);
    collapseSidebar();
  };

  const handleItemClick = (itemName) => {
    collapseSidebar();

    setSelectedItem(itemName);
  };

  const getItemBorderStyle = (itemPath) => {
    return location.pathname === itemPath
      ? { borderLeft: "5px solid rgb(0, 226, 0)" }
      : {};
  };
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <>
      {isEditingBotSidebar && isScreenWidthSmall && (
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="top-right-icon">
                <div className="circle-container" onClick={toggleSidebar}>
                  <ToolsCircleIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {(sidebarVisible || !isScreenWidthSmall) && isEditingBotSidebar && (
        <div
          className={`edit-bot-sidebar-bg ${
            isEditingBotSidebar ? "visibleEdit" : ""
          }`}
        >
          <EditBotSidebarSteps
            handleToggleLeftMenu={handleToggleLeftMenu}
            handleSidebarClose={toggleSidebar}
          />
        </div>
      )}
      {(isScreenWidthSmall && isEditingBotSidebar) ||
        (leftMenuStyle && (
          <div
            o
            id="left-menu"
            onMouseEnter={() => setIsSidebarExpanded(true)}
            onMouseLeave={collapseSidebar}
            className="sidebar-icons"
          >
            <ul>
              <li
                onClick={() => handleItemClick("dashboard")}
                style={getItemBorderStyle("/dashboard")}
              >
                <Link to="/dashboard">
                  <div className="custom-icon">
                    <HomeSolidIcon width="25" fill="#ffffff" />
                  </div>
                </Link>
              </li>
              <li
                onClick={() => handleItemClick("inbox")}
                style={getItemBorderStyle("/dashboard/user/messageshub")}
              >
                <a href="/dashboard/user/messageshub">
                  <div className="custom-icon">
                    <MessageEnvelopIcon width="25" color="#ffffff" />
                  </div>
                </a>
              </li>
              {UserRoles.ADMIN && (
                <li
                  onClick={() => handleItemClick("inbox")}
                  style={getItemBorderStyle("/dashboard/user/overview")}
                >
                  <Link to="/dashboard/user/overview">
                    <div className="custom-icon">
                      <TargetsIcon width="25" color="#ffffff" />
                    </div>
                  </Link>
                </li>
              )}

              {isEditingBotSidebar && (
                <li
                  onClick={() => {
                    handleItemClick("configuration");
                  }}
                  style={getItemBorderStyle(
                    "/dashboard/admin/bot/edit/:chatbotID"
                  )}
                >
                  <Link to="/dashboard/admin/bot/edit/:chatbotID">
                    <div className="custom-icon">
                      <Configuration width="25" color="#ffffff" />
                    </div>
                  </Link>
                </li>
              )}
              <li
                onClick={() => {
                  setShowConfirmModal(true);
                  handleItemClick("signout");
                }}
                style={getItemBorderStyle("/")}
              >
                <Link to="/">
                  <div className="custom-icon">
                    <SignOutIcon width="25" fill="#ffffff" />
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        ))}
      <span>{labelInfo.text}</span>
      <ConfirmationModal
        isOpen={showConfirmModal}
        onConfirm={handleLogout}
        onCancel={handleCancelLogout}
        message="Weet u zeker dat u wilt uitloggen?"
      />
    </>
  );
};

export default PrivateNavbar;
