import React from "react";
import RobolButton from "../../components/RobolButton";
import "./css/ForwardingRequestPopup.scss";
import { ForwardingStatus } from "../../helpers/Enums";
import { forwardOption } from "../../services/api/MessagehubService";
import i18n from 'i18next';

const ForwardingRequestPopup = ({
  forwardAccept,
  setForwardAccept,
  sessionId,
  forwarded_to,
}) => {
  const handleForwardToggle = async () => {
    await forwardOption(sessionId, forwarded_to, ForwardingStatus.IS_FORWARDED);
  };

  const handleRejectForwardToggle = async () => {
    await forwardOption(
      sessionId,
      forwarded_to,
      ForwardingStatus.REJECTED
    );
  };

  return (
    <div className="forwarding-request-modal">
      <div className="popup">
        <h6>{i18n.t('chatbot_messagehub.forwarding.popup')}</h6>
        <div className="row text-center mt-3">
          <div className="col-12 col-md-6">
            <RobolButton theme="dark" onClick={handleForwardToggle}>
            {i18n.t('chatbot_messagehub.forwarding.accept')}
            </RobolButton>
          </div>
          <div className="col-12 col-md-6">
            <RobolButton theme="warning" onClick={handleRejectForwardToggle}>
            {i18n.t('chatbot_messagehub.forwarding.reject')}
            </RobolButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForwardingRequestPopup;
