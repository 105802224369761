import LogoLoader from "./LoadingCard";
import logo from "../assets/Group 142.png";

export const GeneralLoader = () =>  <div className="center-container">
<div className="top p-4">
  <div className="row justify-content-center align-content-center h-100">
    <div className="col-12">
      <LogoLoader src={logo} size="sm" />
    </div>
  </div>
</div>
</div>