import React, { useRef, useState } from "react";
import { useFormikContext } from "formik";
import { UploadIcon, CheckIcon } from "../../assets/svgs/SvgIcons";
import "../../css/PrivateRoutes/DocumentUpload.css";
import { auth } from "../../firebase";
import { uploadScrapedDocument } from "../../services/api/FineTunerService";

export const DocumentUpload = ({ name, onDocumentUpload, chatbotID }) => {
  const { setFieldValue } = useFormikContext();
  const fileInputRef = useRef(null);
  const [fileDropped, setFileDropped] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = async (event) => {
    if (isUploading) return;
    setIsUploading(true);
    console.log("handleFileChange triggered");
    const file = event.target.files[0];
    if (file) {
      try {
        setFileDropped(true);
        setFieldValue(name, file);
        onDocumentUpload(file);
      } catch (error) {
        console.error("Error uploading document:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = async (event) => {
    if (isUploading) return;
    setIsUploading(true);
    console.log("handleDrop triggered");
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    if (file) {
      try {
        setFileDropped(true);
        setFieldValue(name, file);
        onDocumentUpload(file);
      } catch (error) {
        console.error("Error uploading document:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <div className="document-scraper-upload">
      <div
        className={`text-center document-scraper-drop-area ${
          fileDropped ? "file-dropped visible" : ""
        }`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type="file"
          name={name}
          placeholder="Upload files"
          accept=".pdf, .doc, .docx, .xls, .xlsx"
          id="documentScraperUpload"
          ref={fileInputRef}
          className="document-scraper-drop-input"
          onChange={handleFileChange}
        />
        <label
          className="document-scraper-drop-label"
          htmlFor="documentScraperUpload"
        >
          <div className="vertical-center">
            {fileDropped ? (
              <>
                <CheckIcon width="35" height="35" color="#26C87C" /> File
                successfully dropped
              </>
            ) : (
              <>
                <UploadIcon width="35" height="35" color="#1E3050" /> Upload
                PDF, DOC, or Excel files
              </>
            )}
          </div>
        </label>
      </div>
    </div>
  );
};
