import React, { useState } from "react";
import { images } from "../components/images";
import "../css/Signin.css";
import { loginWithEmail, resetPassword } from "../components/FirebaseMethods";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";

export const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { setCurrentUser } = useAuth();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userResponse = await loginWithEmail(email, password);

      setCurrentUser(userResponse);

      navigate("/dashboard");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="container h-100 main-container fade-in-image signin-container">
      <div className="row h-100">
        <div className="col-md-6">
          <div className="login-form-container p-4">
            <h1 className="mb-4">{t("signin_title")}</h1>
            {error && <p className="text-danger">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="username">{t("signin_username_label")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder={t("signin_username_placeholder")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">{t("signin_password_label")}</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder={t("signin_password_placeholder")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button type="submit" className="w-100 btn custom-btn-primary">
                {t("signin_button_text")}
              </button>
            </form>
            <Link to="/reset" style={{ cursor: "pointer" }}>
              {t("signin_reset_password_link")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
