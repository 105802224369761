// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-overview {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 20px;
  padding: 20px;
}

.user-overview>div {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/UserOverview.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,6BAA6B;EAC7B,SAAS;EACT,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".user-overview {\r\n  display: grid;\r\n  grid-template-columns: 1fr 1fr;\r\n  grid-template-rows: auto auto;\r\n  gap: 20px;\r\n  padding: 20px;\r\n}\r\n\r\n.user-overview>div {\r\n  border: 1px solid #ccc;\r\n  padding: 10px;\r\n  border-radius: 4px;\r\n}\r\n\r\n.no-padding-right {\r\n  padding-right: 0 !important;\r\n}\r\n\r\n.no-padding-left {\r\n  padding-left: 0 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
