import React, { useContext, useEffect, useState } from "react";
import i18n from 'i18next';
import { ToastNotifications } from "../helpers/Enums";
import { useNavigate } from "react-router-dom";
import {
  ActivateIcon,
  ArrowBack,
  ArrowDownIcon,
  BrainIcon,
  ContextIcon,
  DesignIcon,
  FAQIcon,
  TestIcon,
} from "../assets/svgs/SvgIcons";
import "../css/PrivateRoutes/EditBotSidebarSteps.css";
import ConfirmationModal from "./ConfirmationModal";
import ActiveStepContext from "../context/EditBotSteps/ActiveStepContext";
import {
  SidebarSubitems,
  SidebarTitles,
} from "../assets/EnumSidebarConfigurator";

const EditBotSidebarSteps = ({ handleToggleLeftMenu, handleSidebarClose, currentValues }) => { // Accept currentValues as a prop
  const [showLeaveConfirmModal, setShowLeaveConfirmModal] = useState(false);
  const [activeIcon, setActiveIcon] = useState(null);
  const [expandedSection, setExpandedSection] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeSubItem, setActiveSubItem] = useState(null);
  const { activeStep, changingStep, setActiveStep } =
    useContext(ActiveStepContext);
  const [isChangingStep, setIsChangingStep] = useState(false);

  const history = useNavigate();

  useEffect(() => {
    if (activeStep) {
      setIsChangingStep(true);
    }
  }, [activeStep]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      handleToggleLeftMenu(false);
    } else {
      handleToggleLeftMenu(true);
    }
  }, [isMobile]);

  const handleIconClick = (iconId) => {
    setActiveIcon((prevActiveIcon) =>
      prevActiveIcon === iconId ? null : iconId
    );
    setExpandedSection((prevExpandedSection) =>
      prevExpandedSection === iconId ? null : iconId
    );
  };

  const handleBackClick = () => {
    setShowLeaveConfirmModal(true);
  };

  const handleSubItemClick = (itemTitle) => {
    setActiveSubItem(itemTitle);
    setActiveStep(itemTitle, currentValues); // Pass current values to setActiveStep
    handleSidebarClose();
  };

  const iconsSize = 25;
  const sidebarSections = [
    {
      title: SidebarTitles.BACK(),
      isNavArrow: true,
      items: [],
      icon: (
        <div onClick={handleBackClick}>
          <ArrowBack width={iconsSize} />
          <span className="d-none"> {SidebarTitles.BACK()}</span>
        </div>
      ),
      customClass: "back-section",
    },
    {
      title: SidebarTitles.INTELLIGENCE(),
      isHeader: true,
    },
    {
      title: SidebarTitles.KNOWLEDGE(),
      items: [
        SidebarSubitems.PERSONALITY(),
        SidebarSubitems.COMPANY(),
        SidebarSubitems.RESTRICTIONS(),
      ],
      icon: (
        <BrainIcon
          width={iconsSize}
          color={activeIcon === i18n.t('chatbot_configuration.ExtraDescription.btn_conf_bot_card') ? "white" : "#1E3050"}
        />
      ),
    },
    {
      title: SidebarTitles.CONTEXT(),
      items: [
        SidebarSubitems.PROMPTS(),
        SidebarSubitems.DOCUMENT_SCRAPERS(),
        SidebarSubitems.WEB_SCRAPERS(),
      ],
      icon: (
        <ContextIcon
          width={iconsSize}
          color={activeIcon === SidebarTitles.CONTEXT() ? "white" : "#1E3050"}
        />
      ),
    },
    {
      title: SidebarTitles.DESIGN(),
      items: [SidebarSubitems.LAYOUT_CONFIGURATION()],
      icon: (
        <DesignIcon
          width={iconsSize}
          color={activeIcon === SidebarTitles.DESIGN() ? "white" : "#1E3050"}
        />
      ),
    },
    {
      title: SidebarTitles.ACTIVATION(),
      isHeader: true,
    },
    {
      title: SidebarTitles.TEST(),
      items: [SidebarSubitems.INTERACTIVE_TEST()],
      icon: (
        <TestIcon
          width={iconsSize}
          color={activeIcon === SidebarTitles.TEST() ? "white" : "#1E3050"}
        />
      ),
    },
    {
      title: SidebarTitles.ACTIVATE(),
      items: [SidebarSubitems.ACTIVATE_CHATBOT()],
      icon: (
        <ActivateIcon
          width={iconsSize}
          color={activeIcon === SidebarTitles.ACTIVATE() ? "white" : "#1E3050"}
        />
      ),
    },
  ];

  const faqSection = [
    {
      title: SidebarTitles.FAQ(),
      isHeader: true,
    },
    {
      title: "F.A.Q.",
      items: [
        {
          text: SidebarSubitems.FAQ_Q1(),
          redirectUrl: "../dashboard/faq/question-1", // Specify the redirect URL here
        },
        {
          text: SidebarSubitems.FAQ_Q2(),
          redirectUrl: "../dashboard/faq/question-1", // Specify the redirect URL here
        },
      ],
      icon: (
        <FAQIcon
          width={iconsSize}
          color={activeIcon === "F.A.Q." ? "white" : "#1E3050"}
        />
      ),
    },
  ];

  const confirmLeavePage = () => {
    history("../dashboard");
  };

  const handleFAQItemClick = (item, redirectUrl) => {
    if (redirectUrl) {
      history(redirectUrl);
    }
  };

  return (
    <div>
      <ConfirmationModal
        isOpen={showLeaveConfirmModal}
        onConfirm={confirmLeavePage}
        onCancel={() => setShowLeaveConfirmModal(false)}
        message={ToastNotifications.BACK_POPUP()}
      />
      <div className={`sidebar-padding ${isMobile ? "mobile-sidebar" : ""}`}>
        {sidebarSections.map((section) => (
          <div
            key={section.title}
            className={`sidebar-section ${isMobile ? "mobile-section" : ""}`}
          >
            {section.isHeader ? (
              <div className="sidebar-divider-header">{section.title}</div>
            ) : (
              <div
                className={`sidebar-section-title ${
                  expandedSection === section.title && section.title !== "Terug"
                    ? "active-sidebar-item"
                    : ""
                }`}
                onClick={() =>
                  section.title !== "Terug"
                    ? handleIconClick(section.title)
                    : handleBackClick()
                }
              >
                <div className="text-and-icon-container">
                  {!isMobile && section.icon}
                  {section.title}
                </div>
                {!section.isNavArrow &&
                  section.items &&
                  section.items.length > 0 &&
                  !isMobile && (
                    <div
                      className={`arrow-wrapper ${
                        expandedSection === section.title ? "rotate" : ""
                      }`}
                    >
                      <ArrowDownIcon width="10" />
                    </div>
                  )}
              </div>
            )}
            {expandedSection === section.title && section.items && (
              <div className="sidebar-item-list">
                {section.items.map((item) => (
                  <li
                    key={item}
                    className={`sidebar-item ${
                      item === activeSubItem ? "active-sidebar-item" : ""
                    }`}
                    onClick={() => handleSubItemClick(item)}
                  >
                    {item}
                  </li>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="sidebar-divider-header">{faqSection.title}</div>
      {faqSection.map((section) => (
        <div
          key={section.title}
          className={`sidebar-section ${isMobile ? "mobile-section" : ""}`}
        >
          {section.isHeader ? (
            <div className="sidebar-divider-header">{section.title}</div>
          ) : (
            <div
              className={`sidebar-section-title ${
                expandedSection === section.title && section.title !== "Terug"
                  ? "active-sidebar-item"
                  : ""
              }`}
              onClick={() =>
                section.title !== "Terug"
                  ? handleIconClick(section.title)
                  : handleBackClick()
              }
            >
              <div className="text-and-icon-container">
                {!isMobile && section.icon}
                {section.title}
              </div>
              {!section.isNavArrow &&
                section.items &&
                section.items.length > 0 &&
                !isMobile && (
                  <div
                    className={`arrow-wrapper ${
                      expandedSection === section.title ? "rotate" : ""
                    }`}
                  >
                    <ArrowDownIcon width="10" />
                  </div>
                )}
            </div>
          )}
          {expandedSection === section.title && section.items && (
            <div className="sidebar-item-list">
              {section.items.map((item) => (
                <li
                  key={item.text}
                  className={`sidebar-item ${
                    item.text === activeSubItem ? "active-sidebar-item" : ""
                  }`}
                  onClick={() =>
                    handleFAQItemClick(item.text, item.redirectUrl)
                  }
                >
                  {item.text}
                </li>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default EditBotSidebarSteps;
