import React from "react";
import { useField } from "formik";
import "./css/FormikToggle.css"; // Adjust the import path as necessary

export const FormikToggle = ({ label, name, onToggle }) => {
  // Correctly destructuring field and helpers from the useField hook.
  const [field, meta, helpers] = useField({ name, type: "checkbox" });

  // This function toggles the checkbox value and submits the form
  const handleChange = () => {
    helpers.setValue(!field.value);
    if (onToggle) {
      onToggle();
    }
  };

  return (
    <div className="toggle-container">
      {label && (
        <label htmlFor={name} className="toggle-label">
          {label}
        </label>
      )}
      <div className="toggle-button mt-2">
        <button
          type="button"
          className={`btn-toggle ${field.value ? "active" : ""}`}
          aria-pressed={field.value}
          onClick={handleChange} // Toggles the value on click and submits the form
        >
          <div className="handle"></div>
        </button>
      </div>
    </div>
  );
};
