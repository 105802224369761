import { ThreeDotsIcon, UserGroupIcon } from "../../assets/svgs/SvgIcons";
import CardWrapper from "./CardWrapper";
import "../../css/PrivateRoutes/UserTable.css";
import DynamicTable from "../DynamicTable";
import { PencilSquare, ThreeDotsVertical } from "react-bootstrap-icons";
import defaultEmployeePic from "../../assets/defaultEmployee.png";
import SearchBar from "../SearchBar";
import RobolButton from "../RobolButton";
import AddUserButton from "../AddUserButton";
import ActionMenu from "../ActionMenu";
import { useState } from "react";
import CardLabelEmpList from "../CardLabelEmpList";
import Pagination from "../Pagination";
const UserTable = () => {
  const [openMenuUserId, setOpenMenuUserId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5; // Set the number of items per page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const usersData = [
    {
      id: 1,
      name: "Amanda Montgomery",
      email: "montgomery@ya.com",
      department: "HR",
      function: "Recruiter",
      avatarUrl: defaultEmployeePic,
    },
    {
      id: 2,
      name: "Amanda Montgomery",
      email: "montgomery@ya.com",
      department: "Verkoop",
      function: "Sales",
      avatarUrl: defaultEmployeePic,
    },
    {
      id: 3,
      name: "Amanda Montgomery",
      email: "montgomery@ya.com",
      department: "IT",
      function: "Developer",
      avatarUrl: defaultEmployeePic,
    },
    {
      id: 4,
      name: "Amanda Montgomery",
      email: "montgomery@ya.com",
      department: "HR",
      function: "Recruiter",
      avatarUrl: defaultEmployeePic,
    },
    {
      id: 5,
      name: "Amanda Montgomery",
      email: "montgomery@ya.com",
      department: "Verkoop",
      function: "Sales",
      avatarUrl: defaultEmployeePic,
    },
    {
      id: 6,
      name: "Amanda Montgomery",
      email: "montgomery@ya.com",
      department: "IT",
      function: "Developer",
      avatarUrl: defaultEmployeePic,
    },
    {
      id: 7,
      name: "Amanda Montgomery",
      email: "montgomery@ya.com",
      department: "HR",
      function: "Recruiter",
      avatarUrl: defaultEmployeePic,
    },
    {
      id: 8,
      name: "Amanda Montgomery",
      email: "montgomery@ya.com",
      department: "Verkoop",
      function: "Sales",
      avatarUrl: defaultEmployeePic,
    },
    {
      id: 9,
      name: "Amanda Montgomery",
      email: "montgomery@ya.com",
      department: "IT",
      function: "Developer",
      avatarUrl: defaultEmployeePic,
    },
    {
      id: 10,
      name: "Amanda Montgomery",
      email: "montgomery@ya.com",
      department: "HR",
      function: "Recruiter",
      avatarUrl: defaultEmployeePic,
    },
    {
      id: 11,
      name: "Amanda Montgomery",
      email: "montgomery@ya.com",
      department: "Verkoop",
      function: "Sales",
      avatarUrl: defaultEmployeePic,
    },
    {
      id: 12,
      name: "Amanda Montgomery",
      email: "montgomery@ya.com",
      department: "IT",
      function: "Developer",
      avatarUrl: defaultEmployeePic,
    },
  ];

  const currentUsers = usersData.slice(indexOfFirstItem, indexOfLastItem);

  const toggleMenu = (userId) => {
    // If the same menu is clicked again, close it, otherwise open the new one
    setOpenMenuUserId(openMenuUserId === userId ? null : userId);
  };

  // Function to handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const columns = [
    {
      Header: "Naam",
      accessor: "name",
      className: "name-column",
      Cell: (data) => (
        <div className="d-flex align-items-center">
          <img
            src={data.avatarUrl || defaultEmployeePic}
            width={"40px"}
            height={"40px"}
            alt={`Avatar of ${data.name}`}
            className="rounded-circle me-2 avatar-img d-none d-md-block" // Hide avatar on small screens
          />
          {/* Wrap the name in a span or div that has a click handler */}
          <span
            className="employee-name"
            onClick={() => console.log("Employee selected", data.id)}
          >
            {data.name}
          </span>
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      className: "d-none d-lg-table-cell actions-column",
    },
    {
      Header: "Afdeling",
      accessor: "department",
      className: "d-none d-xl-table-cell",
    },
    {
      Header: "Functie",
      accessor: "function",
      className: "d-none d-xl-table-cell",
    },
    {
      Header: "Acties",
      accessor: "actions",

      Cell: (cellInfo) => (
        <ActionMenu
          isOpen={openMenuUserId === cellInfo.id}
          toggleMenu={toggleMenu}
          userId={cellInfo.id}
        />
      ),
    },
  ];

  return (
    <div className="container mt-3">
      <CardWrapper>
        <div className="row justify-content-between align-items-center mb-3">
          <div className="col-auto">
            <CardLabelEmpList />
          </div>
          <div className="mt-md-0 mt-3 col-auto">
            <AddUserButton text="Nieuw medewerker" theme="dark" size="l" />
          </div>
        </div>
        <div className=" mb-3">
          <div className="row">
            <SearchBar />
          </div>
        </div>

        <DynamicTable
          columns={columns}
          onRowClick={(row) => {
            console.log("Row clicked", row);
          }}
          data={currentUsers.map((user, index) => ({
            ...user,
            index: index + 1,
            avatarUrl: user.avatarUrl || defaultEmployeePic,
          }))}
        />
        <Pagination
          totalItems={usersData.length}
          itemsPerPageOptions={[5, 10, 20, 50]}
          defaultItemsPerPage={itemsPerPage}
          onChangePage={handlePageChange}
        />
      </CardWrapper>
    </div>
  );
};

export default UserTable;
