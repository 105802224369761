import React, { useState } from "react";
import DynamicTable from "../DynamicTable";
import LiveApiActionMenu from "./LiveApiActionMenu";

const LiveApiTable = ({ liveApis = [] }) => {
  const [liveApiId, setLiveApiId] = useState(null);

  const toggleMenu = (apiId) => {
    if (liveApiId === apiId) {
      setLiveApiId(null); // Close the menu if it's already open
    } else {
      setLiveApiId(apiId); // Open the menu for the selected document
    }
  };

  console.log('liveApis', liveApis);

  const columns = [
    { Header: "Website", accessor: "url"},
    { Header: "apiName", accessor: "apiName" },
    { Header: "apiCategory", accessor: "apiCategory" },
    { Header: "apiKeywords", accessor: "apiKeywords" },
    { Header: "Status", accessor: "status" },
    { Header: "Uploaddatum", accessor: "timestamp" },
    { Header: "Toegevoegd door", accessor: "runned_by" },
    {
      Header: "Acties",
      accessor: "actions",
      Cell: (cellInfo) => (
        <LiveApiActionMenu
          isOpen={liveApiId === cellInfo.name}
          toggleMenu={() => toggleMenu(cellInfo.name)}
          apiId={cellInfo.name}
        />
      ),
    },
  ];

  return (
    <div className="document-table">
      <DynamicTable
        onRowClick={(row) => {
          console.log("Row clicked", row);
        }}
        columns={columns}
        data={liveApis}
      />
      {liveApis.length === 0 && (
        <table className="table mb-3 purple-text-table">
          <tbody>
            <tr>
              <td colSpan={columns.length} className="text-center">
                Geen resultaten gevonden.
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default LiveApiTable;
