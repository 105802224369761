import React from "react";
import { Field, useField } from "formik";

export const FormikSelect = ({ label, name, options, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <Field name={name}>
      {({ field, form: { touched, errors } }) => (
        <div className="form-group">
          {/* Render label if it's provided */}
          {label && <label htmlFor={name}>{label}</label>}

          <select
            className={`form-control ${
              meta.touched && meta.error ? "is-invalid" : ""
            }`}
            {...field}
            {...props}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {/* Show validation error if any */}
          {touched[field.name] && errors[field.name] && (
            <div className="invalid-feedback">{errors[field.name]}</div>
          )}
        </div>
      )}
    </Field>
  );
};
