import React from "react";
import { IconColor } from "../../assets/svgs/SvgIconColors";
import { StatsIcon } from "../../assets/svgs/SvgIcons";
import UserOverviewCardWrapper from "../../components/PrivateRoutes/UserOverviewCardWrapper";
import i18n from 'i18next';

export const AiStats = ({
  average_acceptance_time,
  average_chat_duration,
  conversations_today,
  users_available,
  users_total,
}) => {
  const statsData = [
    {
      label: i18n.t('chatbot_statistics.number_of_conv'),
      value: conversations_today || i18n.t('chatbot_statistics.empty_calculation'),
    },
    {
      label: i18n.t('chatbot_statistics.avg_waiting_time'),
      value: average_acceptance_time || i18n.t('chatbot_statistics.empty_calculation'),
    },
    {
      label: i18n.t('chatbot_statistics.avg_duration_conv'),
      value: average_chat_duration || i18n.t('chatbot_statistics.empty_calculation'),
    },
    {
      label: i18n.t('chatbot_statistics.number_of_employees'),
      value: users_total || i18n.t('chatbot_statistics.empty_calculation'),
    },
    {
      label: i18n.t('chatbot_statistics.available_employees'),
      value: users_available || i18n.t('chatbot_statistics.empty_calculation'),
    },
  ];

  return (
    <UserOverviewCardWrapper minHeight="15vh">
      <div className="p-3 header-content d-flex justify-content-between">
        <span className="employee-name">{i18n.t('chatbot_statistics.title')}</span>
        <div className="header-right ml-auto">
          <StatsIcon color={IconColor.PRIMARY} width="25px" />
        </div>
      </div>
      <div className="px-5">
        <div className="container">
          <div className="row">
            <ul className="list-unstyled w-100">
              {statsData.map((stat, index) => (
                <li
                  key={index}
                  className="d-flex justify-content-between align-items-center py-2"
                >
                  <span className="stat-label">{stat.label}</span>
                  <span className="stat-value font-weight-bold text-right">
                    {stat.value}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </UserOverviewCardWrapper>
  );
};
