import React from "react";
import moment from "moment";
import defaultAvatar from "../../assets/defaultEmployee.png";

export const EmailListItem = ({
  conversation,
  onEmailClicked,
  selectedConversation,
  forwardAccept,
  currentSection,
}) => {
  if (!conversation || !conversation.conversation_data) {
    return null;
  }
  const isSelected = conversation.id === selectedConversation?.id;

  let conversationData;
  try {
    conversationData =
      typeof conversation.conversation_data === "string"
        ? JSON.parse(conversation.conversation_data)
        : conversation.conversation_data;
  } catch (error) {
    console.error("Error parsing conversation_data:", error);
    return null; // Exit rendering if parsing fails
  }

  // Ensure conversationData is an array before filtering
  if (!Array.isArray(conversationData)) {
    console.error(
      "Expected conversationData to be an array:",
      conversationData
    );
    return null; // Exit rendering if conversationData is not an array
  }

  const lastUserMessage = conversationData[conversationData.length - 1];
  const lastMessageContent = lastUserMessage?.content || "No Subject";
  const id = conversation?.id;
  const started_at = conversation?.started_at;
  const isUnread = conversation?.is_read === 0;
  const messageDate = moment(started_at);
  const currentDate = moment();

  return (
    <div
      className={`email-item ${isUnread ? "unread" : ""} ${
        isUnread ? "new-message" : ""
      } ${isSelected && "selected"}`}
      onClick={() => onEmailClicked(conversation, currentSection)}
    >
      <small className="email-item__subject truncate">#{id}</small>
      {isUnread && (
        <b className="email-item__subject truncate">{lastMessageContent}</b>
      )}
      {!isUnread && (
        <span className="email-item__subject truncate">
          {lastMessageContent}
        </span>
      )}
      {forwardAccept && <img src={defaultAvatar} className="user-picture" />}
      <small className="email-item__subject truncate">
        {currentDate.diff(messageDate, "hours") > 24
          ? messageDate.format("YYYY-MM-DD HH:mm:ss")
          : messageDate.format("HH:mm")}
      </small>
      <div className="email-item__details"></div>
    </div>
  );
};
