// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ActionMenu.css */

.action-menu-container {
    position: relative;
    display: inline-block;
}

.action-menu-icon {
    color: grey;
    cursor: pointer;
}

.action-menu-icon:hover {
    color: black;
}

.action-menu-dropdown {
    opacity: 0;
    visibility: hidden;
    /* Removed the first transform declaration */
    transition: opacity 0.5s ease, transform 0.5s ease, visibility 0s 0.5s;
    position: absolute;
    top: -30px;
    /* Adjusted from -40px to 20px to appear below the icon */
    right: 20px;
    background-color: #fff;
    border-radius: 5px;
    z-index: 99;
    padding: 3px;
    /* The transform here will be overridden by .action-menu-dropdown-visible when active */
    transform: translateY(10px);
}

.action-menu-dropdown-visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 0.2s ease, transform 0.2s ease;
    /* Adjusted the delay */
}



.action-menu-dropdown button .icon {
    margin-right: 8px;
}

/* Add styling for hover effects */
.action-menu-dropdown button:hover {
    background-color: #f2f2f2;
}

.icon {
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/css/ActionMenu.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,4CAA4C;IAC5C,sEAAsE;IACtE,kBAAkB;IAClB,UAAU;IACV,yDAAyD;IACzD,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,uFAAuF;IACvF,2BAA2B;AAC/B;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,wBAAwB;IACxB,kDAAkD;IAClD,uBAAuB;AAC3B;;;;AAIA;IACI,iBAAiB;AACrB;;AAEA,kCAAkC;AAClC;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["/* ActionMenu.css */\r\n\r\n.action-menu-container {\r\n    position: relative;\r\n    display: inline-block;\r\n}\r\n\r\n.action-menu-icon {\r\n    color: grey;\r\n    cursor: pointer;\r\n}\r\n\r\n.action-menu-icon:hover {\r\n    color: black;\r\n}\r\n\r\n.action-menu-dropdown {\r\n    opacity: 0;\r\n    visibility: hidden;\r\n    /* Removed the first transform declaration */\r\n    transition: opacity 0.5s ease, transform 0.5s ease, visibility 0s 0.5s;\r\n    position: absolute;\r\n    top: -30px;\r\n    /* Adjusted from -40px to 20px to appear below the icon */\r\n    right: 20px;\r\n    background-color: #fff;\r\n    border-radius: 5px;\r\n    z-index: 99;\r\n    padding: 3px;\r\n    /* The transform here will be overridden by .action-menu-dropdown-visible when active */\r\n    transform: translateY(10px);\r\n}\r\n\r\n.action-menu-dropdown-visible {\r\n    opacity: 1;\r\n    visibility: visible;\r\n    transform: translateY(0);\r\n    transition: opacity 0.2s ease, transform 0.2s ease;\r\n    /* Adjusted the delay */\r\n}\r\n\r\n\r\n\r\n.action-menu-dropdown button .icon {\r\n    margin-right: 8px;\r\n}\r\n\r\n/* Add styling for hover effects */\r\n.action-menu-dropdown button:hover {\r\n    background-color: #f2f2f2;\r\n}\r\n\r\n.icon {\r\n    margin-right: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
