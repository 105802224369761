// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat_modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 0%, 0.486);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat_modalcontent {
    margin: 1rem;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.863);
    max-width: 400px;
    text-align: center;
    color: #000;

}

`, "",{"version":3,"sources":["webpack://./src/css/ConfirmationModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,wCAAwC;IACxC,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,6CAA6C;IAC7C,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;;AAEf","sourcesContent":[".chat_modal {\r\n    display: none;\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background-color: hsla(0, 0%, 0%, 0.486);\r\n    z-index: 99999;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.chat_modalcontent {\r\n    margin: 1rem;\r\n    background-color: #ffffff;\r\n    padding: 20px;\r\n    border-radius: 5px;\r\n    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.863);\r\n    max-width: 400px;\r\n    text-align: center;\r\n    color: #000;\r\n\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
