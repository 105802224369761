import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CrossIcon } from "../../assets/svgs/SvgIcons";
import { ToastNotifications } from "../../helpers/Enums";

const TopbarForwardRequest = ({ show, setCurrentSection }) => {
  // Effect to trigger toast based on the show prop
  useEffect(() => {
    if (show) {
      notify();
    }
  }, [show]); // Dependency array includes show to react on its changes

  const handleToggleViewForwardings = () => {
    setCurrentSection("forward");
  };

  //TODO: Translation
  const notify = () =>
    toast(
      <div>
        {ToastNotifications.NEW_REQUEST()}{" "}
        <a
          onClick={() => handleToggleViewForwardings()}
          style={{ color: "#FFD700" }}
        >
          {ToastNotifications.CLICK_HER()}
        </a>{" "}
        {ToastNotifications.TO_VIEW()}
      </div>,
      {
        style: {
          backgroundColor: "#1E3050", // Dark background color
          color: "#fff", // White text color
        },
      }
    );

  // Custom Close Button with style
  const CustomCloseButton = ({ closeToast }) => (
    <CrossIcon color="#fff" width="10px" closeToast={closeToast} />
  );

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        closeButton={CustomCloseButton}
        style={{
          width: "100%",
          maxWidth: "600px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
    </div>
  );
};

export default TopbarForwardRequest;
