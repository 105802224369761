import React, { useEffect, useState } from "react";
import { IconColor } from "../../assets/svgs/SvgIconColors";
import { MessageEnvelopIcon, PasteIcon } from "../../assets/svgs/SvgIcons";
import UserOverviewCardWrapper from "../../components/PrivateRoutes/UserOverviewCardWrapper";
import RobolButton from "../../components/RobolButton";
import { useWebSocket } from "../../context/WebSocketContext";
import moment from "moment";
import { Link } from "react-bootstrap-icons";
import i18n from 'i18next';

export const LiveMessages = ({
  setCopyMessageToAiAssistent,
  latestMessages,
}) => {
  const handleCopyMessage = (message) => {
    setCopyMessageToAiAssistent(message);
  };

  const formatTimestamp = (startedAt) => {
    const momentTimestamp = moment(startedAt);
    const today = moment().startOf("day");
    const messageDate = moment(startedAt);

    if (messageDate.isSame(today, "day")) {
      return momentTimestamp.format("HH:mm");
    } else {
      return momentTimestamp.format("YYYY-MM-DD HH:mm:ss");
    }
  };

  return (
    <UserOverviewCardWrapper minHeight="50vh">
      <div className="p-3 header-content d-flex justify-content-between">
        <span className="employee-name">{i18n.t('chatbot_statistics.live_msg')}</span>
        <div className="header-right ml-auto">
          <MessageEnvelopIcon color={IconColor.PRIMARY} width="25px" />
        </div>
      </div>
      {latestMessages.map((message, index) => (
        <div className="px-3" key={index}>
          <div
            className={`container message-wrapper my-2 p-4 rounded shadow-sm ${
              message.isUnread ? "new-message" : ""
            }`}
            style={{ cursor: "pointer", position: "relative" }}
          >
            <div className="row">
              <div className="col-auto font-weight-bold">
              {i18n.t('chatbot_statistics.msg')} #{index + 1} {message.senderInfo}
              </div>
              <div className="col text-right">
                {formatTimestamp(message.started_at)}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 py-2 rounded">
                {message.isUnread ? <b>{message.content}</b> : message.content}
              </div>
            </div>
            <div
              onClick={() => {
                handleCopyMessage(message.content);
              }}
              className="copy-icon"
            >
              <PasteIcon color={IconColor.PRIMARY} width="20px" />
            </div>
          </div>
        </div>
      ))}
      <div className="p-3 align-self-end">
        <a href="/dashboard/user/messageshub">
          <RobolButton theme="green" outline>
          {i18n.t('chatbot_statistics.msghub_btn')}
          </RobolButton>
        </a>
      </div>
    </UserOverviewCardWrapper>
  );
};
