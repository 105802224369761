// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ActionMenu.css */

.action-menu-container {
    position: relative;
    display: inline-block;
}

.action-menu-icon {
    color: grey;
    cursor: pointer;
}

.action-menu-icon:hover {
    color: black;
}

.action-menu-dropdown {
    position: absolute;
    top: -40px;
    transform: translateY(10px);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    z-index: 100;
    background-color: white;
    border-radius: 4px;
    padding: 10px;
    z-index: 99999 !important;
}

.action-menu-dropdown button {
    display: block;
    background: none;
    border: none;
    padding: 5px 10px;
    text-align: left;
    width: 100%;
    cursor: pointer;
}

.action-menu-dropdown button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 8px 12px;
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.action-menu-dropdown button .icon {
    margin-right: 8px;
}

/* Add styling for hover effects */
.action-menu-dropdown button:hover {
    background-color: #f2f2f2;
}

.icon {
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/DocumentActionMenu.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,2BAA2B;IAC3B,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,WAAW;IACX,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA,kCAAkC;AAClC;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["/* ActionMenu.css */\r\n\r\n.action-menu-container {\r\n    position: relative;\r\n    display: inline-block;\r\n}\r\n\r\n.action-menu-icon {\r\n    color: grey;\r\n    cursor: pointer;\r\n}\r\n\r\n.action-menu-icon:hover {\r\n    color: black;\r\n}\r\n\r\n.action-menu-dropdown {\r\n    position: absolute;\r\n    top: -40px;\r\n    transform: translateY(10px);\r\n    background-color: #fff;\r\n    border: 1px solid #ddd;\r\n    border-radius: 5px;\r\n    z-index: 100;\r\n    background-color: white;\r\n    border-radius: 4px;\r\n    padding: 10px;\r\n    z-index: 99999 !important;\r\n}\r\n\r\n.action-menu-dropdown button {\r\n    display: block;\r\n    background: none;\r\n    border: none;\r\n    padding: 5px 10px;\r\n    text-align: left;\r\n    width: 100%;\r\n    cursor: pointer;\r\n}\r\n\r\n.action-menu-dropdown button {\r\n    display: flex;\r\n    align-items: center;\r\n    background: none;\r\n    border: none;\r\n    padding: 8px 12px;\r\n    width: 100%;\r\n    text-align: left;\r\n    cursor: pointer;\r\n}\r\n\r\n.action-menu-dropdown button .icon {\r\n    margin-right: 8px;\r\n}\r\n\r\n/* Add styling for hover effects */\r\n.action-menu-dropdown button:hover {\r\n    background-color: #f2f2f2;\r\n}\r\n\r\n.icon {\r\n    margin-right: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
