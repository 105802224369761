import React, { useState } from "react";
import { images } from "../components/images";
import "../css/Signin.css";
import {
  registerWithEmail,
  resetPassword,
} from "../components/FirebaseMethods";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { registerCompany } from "../services/api/UserServices";
import { auth } from "../firebase";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import the Eye icon for password peek

export const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [error, setError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const { setCurrentUser } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setPasswordError(t("password_not_confirmed"));
      return;
    }

    // Password complexity requirements
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    if (!passwordRegex.test(password)) {
      setPasswordError(t("password_too_weak"));
      return;
    }

    try {
      const companyResponse = await registerCompany(companyName, email);
      if (companyResponse.message === "Company registered successfully!") {
        const userResponse = await registerWithEmail(email, password);

        // If user is registered successfully, send the welcome email
        if (userResponse) {
          try {
            // Call the /welcome endpoint to send the welcome email
            const welcomeEmailResponse = await sendWelcomeEmail(email);
            console.log("Welcome email sent:", welcomeEmailResponse);
          } catch (emailError) {
            console.error("Error sending welcome email:", emailError);
            setError(emailError.message);
          }
        }

        setCurrentUser(userResponse);
        navigate("/dashboard");
      } else {
        setError(companyResponse?.message);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  // Function to call the /welcome endpoint
  const sendWelcomeEmail = async (email) => {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/welcome`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, name: companyName }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  };

  return (
    <div className="container-fluid h-100 main-container fade-in-image signin-container">
      <div className="row align-items-center justify-content-center h-100">
        <div className="col-md-6">
          <div className="login-form-container p-4">
            <h1 className="mb-4">{t("register_title")}</h1>
            {error && <p className="text-danger">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="companyName">
                  {t("register_company_name_label")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="companyName"
                  placeholder={t("register_company_name_placeholder")}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="username">{t("register_username_label")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder={t("register_username_placeholder")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">{t("register_password_label")}</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="password"
                    placeholder={t("register_password_placeholder")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">
                  {t("register_confirm_password_label")}
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  placeholder={t("register__confirm_password_placeholder")}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              {passwordError && <p className="text-danger">{passwordError}</p>}
              <button type="submit" className="w-100 btn custom-btn-primary">
                {t("register_button_text")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
