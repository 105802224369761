import React from "react";
import UserOverviewCardWrapper from "../../components/PrivateRoutes/UserOverviewCardWrapper";
import ChatbotIntern from "../../components/PrivateRoutes/ChatbotIntern";

export const PersonalAssistant = ({ copyMessageToAiAssistent, setIsAiTyping }) => {
  const chatBotID = "a88c0e68-0a22-42bb-8352-590e67053b01"; //intern-ai
  return (
    <UserOverviewCardWrapper minHeight="50vh">
      <ChatbotIntern chatBotID={chatBotID} copyMessageToAiAssistent={copyMessageToAiAssistent} />
    </UserOverviewCardWrapper>
  );
};
