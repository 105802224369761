import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useConversations } from "../../context/ConversationsContext";
import { useWebSocket } from "../../context/WebSocketContext";
import "../../css/PrivateRoutes/InboxGeneral.scss";
import TopbarForwardRequest from "../../formik/components/TopbarForwardRequest";
import { ForwardingStatus } from "../../helpers/Enums";
import { sortingOptionsInbox } from "../../helpers/helpers";
import {
  availableCompanyUsers,
  markConversationAsOpen,
  updateConversation,
  updateConversationIsRead,
} from "../../services/api/UserServices";
import { EmailDetails } from "./EmailDetails";
import { EmailList } from "./EmailList";
import { Sidebar } from "./Sidebar";
import { GeneralLoader } from "../../components/GeneralLoader";

const InboxGeneral = ({ role }) => {
  const [filteredConversations, setFilteredConversations] = useState([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [emails, setEmails] = useState([]);
  const [selectedEmailId, setSelectedEmailId] = useState(0);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [currentSection, setCurrentSection] = useState("inbox");
  const [userMessage, setUserMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [forwardAccept, setForwardAccept] = useState(false);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Nieuw-Oud");
  const [companyUsers, setCompanyUsers] = useState([]);
  const [sortOrder, setSortOrder] = useState("Nieuw-Oud");

  const [showTopbarForwardRequest, setShowTopbarForwardRequest] =
    useState(false);

  const { updateConversationReadStatus } = useConversations();

  const { currentUser } = useAuth();
  const socket = useWebSocket();
  const [userEmail] = useState(currentUser?.email);

  const handleSectionClick = (section) => {
    if (section !== currentSection) {
      setSelectedEmailId(null);
    }
    if (selectedEmail) {
      setSelectedEmailId(section.id);
      setCurrentSection("user");
    }
    setCurrentSection(section);
  };

  const fetchCompanyUsers = async () => {
    try {
      const responseData = await availableCompanyUsers(userEmail);

      if (responseData.success && Array.isArray(responseData.data)) {
        const { data } = responseData;
        setCompanyUsers(data);
      } else {
        console.error("Received invalid data format:", responseData);
      }
    } catch (error) {
      console.error("Error fetching company users:", error);
    }
  };

  useEffect(() => {
    fetchCompanyUsers();
  }, []);

  useEffect(() => {
    if (selectedOption === "Nieuw-Oud") {
      setFilteredConversations(
        [...conversations].sort((a, b) => new Date(b.date) - new Date(a.date))
      );
    } else if (selectedOption === "Oud-nieuw") {
      setFilteredConversations(
        [...conversations].sort((a, b) => new Date(a.date) - new Date(b.date))
      );
    }
  }, [selectedOption, conversations]);

  useEffect(() => {
    if (!socket) return;

    const handleWebSocketMessages = (event) => {
      try {
        const receivedData = JSON.parse(event.data);
        if (
          receivedData.type === "conversationData" &&
          Array.isArray(receivedData.data)
        ) {
          const sortedConversations = receivedData.data.sort(
            (a, b) => new Date(b.started_at) - new Date(a.started_at)
          );
          setConversations(sortedConversations);
          setLoading(false);
        } else {
          console.error(
            "Received data is not in the expected format:",
            receivedData
          );
        }
      } catch (error) {
        console.error("Error parsing WebSocket data:", error);
      }
    };

    socket.onmessage = handleWebSocketMessages;

    return () => {
      socket.onmessage = null;
    };
  }, [socket]);

  useEffect(() => {
    if (selectedConversation) {
      const updatedConversation = conversations.find(
        (c) => c.sessionId === selectedConversation.sessionId
      );

      if (
        updatedConversation &&
        updatedConversation.conversation_data !==
          selectedConversation.conversation_data
      ) {
        setSelectedConversation(updatedConversation);
      }
    }
  }, [conversations]);

  const selectConversation = (conversation) => {
    setSelectedConversation(conversation);
  };

  useEffect(() => {
    const selectedConversationId = selectedConversation
      ? selectedConversation.id
      : null;

    const updateOpenStatus = async (conversationId, isOpen) => {
      if (conversationId) {
        await markConversationAsOpen(conversationId, isOpen);
      }
    };

    updateOpenStatus(selectedConversationId, 1);

    return () => {
      updateOpenStatus(selectedConversationId, 0);
    };
  }, [selectedConversation]);

  const setSidebarSection = (section) => {
    if (section !== currentSection) {
      setSelectedEmailId("");
    }
    setCurrentSection(section);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = async () => {
    if (!selectedConversation || !userMessage) {
      return;
    }

    const newMessage = {
      role: "remote-worker",
      content: userMessage,
    };

    const updatedConversationData = [
      ...selectedConversation.conversation_data,
      newMessage,
    ];
    await updateConversation(
      selectedConversation.sessionId,
      updatedConversationData,
      selectedConversation.forwarded_to, //in testing
      userEmail
    );

    setUserMessage("");
  };

  const handleSelectConvo = async (conversation) => {
    selectConversation(conversation);
    const isRead = 1;
    await markConversationAsOpen(conversation.id, isRead);
    await updateConversationIsRead(conversation.id, isRead);
    updateConversationReadStatus(conversation.id, isRead);
    setConversations((currentConversations) =>
      currentConversations.map((convo) => {
        if (convo.id === conversation.id) {
          return { ...convo, is_read: isRead };
        }
        return convo;
      })
    );
  };

  useEffect(() => {
    const handleIncomingForward = async () => {
      conversations.forEach((convo) => {
        if (
          convo.forward === ForwardingStatus.FORWARD_REQUESTED &&
          convo.is_read === 0
        ) {
          setShowTopbarForwardRequest(true);
        }
      });
    };
    handleIncomingForward();
  }, [conversations]);

  if (loading) {
    return <GeneralLoader />;
  }

  return (
    <div className="container-fluid">
      <TopbarForwardRequest
        show={showTopbarForwardRequest}
        setCurrentSection={setCurrentSection}
      />
      {!isMobile && (
        <div className="row">
          <div className="col-md-3 p-0">
            <Sidebar
              setSelectedEmail={setSelectedEmail}
              selectedEmail={selectedEmail}
              companyUsers={companyUsers}
              handleSectionClick={handleSectionClick}
              setSelectedEmailId={setSelectedEmailId}
              setCurrentSection={setCurrentSection}
              conversations={conversations}
              setSidebarSection={setSidebarSection}
              currentSection={currentSection}
              role={role}
            />
          </div>
          <div className="col-md-9 p-0">
            <EmailList
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              setSelectedConversation={setSelectedConversation}
              selectedEmail={selectedEmail}
              forwardAccept={forwardAccept}
              setFilteredConversations={setFilteredConversations}
              filteredConversations={filteredConversations}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setShowSortOptions={setShowSortOptions}
              showSortOptions={showSortOptions}
              selectedConversation={selectedConversation}
              isMobile={isMobile}
              conversations={conversations}
              emails={emails.filter((x) => x.tag === currentSection)}
              onEmailSelected={(converstation) =>
                handleSelectConvo(converstation)
              }
              selectedEmailId={selectedEmailId}
              currentSection={currentSection}
            />
            <EmailDetails
              forwardAccept={forwardAccept}
              setForwardAccept={setForwardAccept}
              isMobile={isMobile}
              handleKeyDown={handleKeyDown}
              userMessage={userMessage}
              setUserMessage={setUserMessage}
              sendMessage={sendMessage}
              selectedConversation={selectedConversation}
              conversations={conversations}
              email={emails.find((x) => x.id === selectedEmailId)}
              // onDelete={(id) => deleteMessage(id)}
            />
          </div>
        </div>
      )}
      {isMobile && (
        <div className="row">
          <div className="col-md-2 p-0">
            <Sidebar
              setSelectedEmail={setSelectedEmail}
              selectedEmail={selectedEmail}
              companyUsers={companyUsers}
              handleSectionClick={handleSectionClick}
              setSelectedEmailId={setSelectedEmailId}
              setCurrentSection={setCurrentSection}
              conversations={conversations}
              setSidebarSection={setSidebarSection}
              currentSection={currentSection}
            />
          </div>
          <div className="col-md-12 p-0">
            <EmailList
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              setSelectedConversation={setSelectedConversation}
              selectedEmail={selectedEmail}
              forwardAccept={forwardAccept}
              setFilteredConversations={setFilteredConversations}
              filteredConversations={filteredConversations}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setShowSortOptions={setShowSortOptions}
              showSortOptions={showSortOptions}
              selectedConversation={selectedConversation}
              isMobile={isMobile}
              conversations={conversations}
              emails={emails.filter((x) => x.tag === currentSection)}
              onEmailSelected={(converstation) =>
                handleSelectConvo(converstation)
              }
              selectedEmailId={selectedEmailId}
              currentSection={currentSection}
            />
          </div>
          <div className="col-md-12 p-0">
            <EmailDetails
              isMobile={isMobile}
              handleKeyDown={handleKeyDown}
              userMessage={userMessage}
              setUserMessage={setUserMessage}
              sendMessage={sendMessage}
              selectedConversation={selectedConversation}
              conversations={conversations}
              email={emails.find((x) => x.id === selectedEmailId)}
              // onDelete={(id) => deleteMessage(id)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InboxGeneral;
