import moment from "moment";
import "moment/locale/nl";

export const isValidURL = (url) => {
  // Regular expression to validate URLs in the format: https://www.example.com or https://example.com
  const urlPattern =
    /^(https:\/\/(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\/[^\s]*)?$/;
  return urlPattern.test(url);
};

export const currentTime = moment().format("HH:mm dddd, DD-MM-YYYY");

export const isMobile = window.innerWidth <= 768; 


export const sortingOptionsInbox = ['Nieuw-Oud', "Oud-nieuw"];
