import React, { useEffect, useState } from "react";
/*global FB*/

const FacebookAuth = () => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: "1261000044559383",
        cookie: true,
        xfbml: true,
        version: "v17.0",
      });
      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const FBLoginButton = () => {
    const handleLogin = () => {
      window.FB.login(
        (response) => {
          console.log(response);
        },
        {
          config_id: "864416511779045", // Replace with your configuration ID
        }
      );
    };

    return <button onClick={handleLogin}>Login with Facebook</button>;
  };

  return (
    <div className="container mt-5">
      <div>
        <FBLoginButton />
      </div>

      {/* {!login && (
        <button onClick={handleFacebookLogin}>Login with Facebook</button>
      )}

      {login && webhookCreated && (
        <div className="container">
          <div className="card" style={{ width: "18rem" }}>
            <img
              src={fbConfig?.photoURL}
              className="card-img-top"
              alt="Profile Picture"
            />
            <div className="card-body">
              <h5 className="card-title">{fbConfig?.displayName}</h5>
              <p className="card-text">{fbConfig?.email}</p>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default FacebookAuth;
