import React, { useState, useEffect } from "react";
import DynamicTable from "../DynamicTable";
import DocumentActionMenu from "./DocumentActionMenu";
import Tooltip from "../../helpers/Tooltip";
import { TablesDescription } from "../../helpers/Enums";

const WebScrapeTable = ({ scrapedWebsite = [], onDelete }) => {
  const [webScrapedId, setWebScrapedId] = useState(null);
  const [scrapedData, setScrapedData] = useState([]);

  useEffect(() => {
    setScrapedData(scrapedWebsite); // Ustawienie początkowych danych przy ładowaniu komponentu lub aktualizacji scrapedWebsite
  }, [scrapedWebsite]);

  const toggleMenu = (documentId) => {
    setWebScrapedId(webScrapedId === documentId ? null : documentId);
  };

  const handleDelete = (documentId) => {
    onDelete(documentId); // Wywołanie onDelete, aby usunąć z bazy danych
    setScrapedData(scrapedData.filter((item) => item.id !== documentId)); // Usunięcie z widoku
  };

  const columns = [
    { Header: TablesDescription.WEBSITENAME(), accessor: "url" },
    { Header: TablesDescription.STATUS(), accessor: "status" },
    { Header: TablesDescription.UPLOAD_DATE(), accessor: "timestamp" },
    {
      Header: TablesDescription.ACTIONS(),
      accessor: "actions",
      Cell: ({ original }) => (
        <DocumentActionMenu
          isOpen={webScrapedId === original.id}
          toggleMenu={toggleMenu}
          documentId={original.id}
          onDelete={() => handleDelete(original.id)} // Używamy handleDelete zamiast onDelete
        />
      ),
    },
  ];

  return (
    <div className="document-table">
      <DynamicTable
        onRowClick={(row) => {
          console.log("Row clicked", row);
        }}
        columns={columns}
        data={scrapedData} // Przekazujemy scrapedData, a nie scrapedWebsite
      />
      {scrapedData.length === 0 && (
        <table className="table mb-3 purple-text-table">
          <tbody>
            <tr>
              <td colSpan={columns.length} className="text-center">
                {TablesDescription.DESCRIPTION_WEBSCRAP()}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default WebScrapeTable;
