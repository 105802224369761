import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  FacebookAuthProvider,
  signInWithPopup,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../firebase";

export const registerWithEmail = async (email, password) => {
  try {
    // Create the user with email and password
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    // Send a verification email
    await sendEmailVerification(userCredential.user);

    // Return the user's info
    return userCredential.user;
  } catch (error) {
    // Handle any errors that occur
    throw error;
  }
};

export const resetPassword = async (email) => {
  console.log("email higher", email);
  try {
    await sendPasswordResetEmail(email);
    console.log("Password reset email sent successfully!");
  } catch (error) {
    console.error("Password reset error:", error);
    throw error; // Rethrow the error to be caught in the component
  }
};

export const loginWithEmail = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    throw error;
  }
};

export const loginWithFacebook = async () => {
  try {
    const provider = new FacebookAuthProvider();
    const userCredential = await signInWithPopup(auth, provider);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};
