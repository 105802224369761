export const stepsEmployeeForm = [
  'chatbot_configuration.Employee_creation_tab.personal_data',
  'chatbot_configuration.Employee_creation_tab.work_schedule',
  'chatbot_configuration.Employee_creation_tab.leave',
];

export const roleSelectbox = [
  { label: 'chatbot_configuration.Employee_creation_tab.choose_role', value: 0 },
  { label: 'ADMIN', value: 1 },
  { label: 'USER', value: 2 },
];

export const fieldLabels = {
  color_primary: "Primaire kleur",
  color_secondary: "Secundaire kleur",
  color_button: "Kleur van de knop",
  color_tertiary: "Tertiaire kleur",
};

export const toggleSwitches = [{ name: "available", label: "Beschikbaar" }];
