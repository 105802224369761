// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar {
    position: relative;
}

.search-input {
    width: 50%;
    padding-left: 40px;
    /* Adjust padding to make room for the icon */
    border-radius: 5px !important;
    /* Increased border-radius for rounded corners */
    border: 1px solid #dddddd73;
    /* Lighter border color */
    font-size: 1rem;
    /* Adjust font size as needed */
    height: 40px;
    /* Adjust height as needed */
    outline: none;
    /* Removes the blue outline on focus */
    box-shadow: none;
    /* Removes the default bootstrap shadow if any */
    transition: border-color 0.2s;
    /* Smooth transition for border color */
}

.search-input:focus {
    border-color: #aaa;
    /* Slightly darker border on focus for visibility */
}

.search-bar svg {
    position: absolute;
    left: 12px;
    /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    color: #1E3050;
    /* Icon color */
}

/* If you're using a framework like Bootstrap, ensure your styles override the framework's styles */
.form-control:focus {
    box-shadow: none;
    /* Removes the default bootstrap shadow on focus */
}`, "",{"version":3,"sources":["webpack://./src/css/SearchBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,6CAA6C;IAC7C,6BAA6B;IAC7B,gDAAgD;IAChD,2BAA2B;IAC3B,yBAAyB;IACzB,eAAe;IACf,+BAA+B;IAC/B,YAAY;IACZ,4BAA4B;IAC5B,aAAa;IACb,sCAAsC;IACtC,gBAAgB;IAChB,gDAAgD;IAChD,6BAA6B;IAC7B,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;IAClB,mDAAmD;AACvD;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,qBAAqB;IACrB,QAAQ;IACR,2BAA2B;IAC3B,WAAW;IACX,cAAc;IACd,eAAe;AACnB;;AAEA,mGAAmG;AACnG;IACI,gBAAgB;IAChB,kDAAkD;AACtD","sourcesContent":[".search-bar {\r\n    position: relative;\r\n}\r\n\r\n.search-input {\r\n    width: 50%;\r\n    padding-left: 40px;\r\n    /* Adjust padding to make room for the icon */\r\n    border-radius: 5px !important;\r\n    /* Increased border-radius for rounded corners */\r\n    border: 1px solid #dddddd73;\r\n    /* Lighter border color */\r\n    font-size: 1rem;\r\n    /* Adjust font size as needed */\r\n    height: 40px;\r\n    /* Adjust height as needed */\r\n    outline: none;\r\n    /* Removes the blue outline on focus */\r\n    box-shadow: none;\r\n    /* Removes the default bootstrap shadow if any */\r\n    transition: border-color 0.2s;\r\n    /* Smooth transition for border color */\r\n}\r\n\r\n.search-input:focus {\r\n    border-color: #aaa;\r\n    /* Slightly darker border on focus for visibility */\r\n}\r\n\r\n.search-bar svg {\r\n    position: absolute;\r\n    left: 12px;\r\n    /* Adjust as needed */\r\n    top: 50%;\r\n    transform: translateY(-50%);\r\n    z-index: 10;\r\n    color: #1E3050;\r\n    /* Icon color */\r\n}\r\n\r\n/* If you're using a framework like Bootstrap, ensure your styles override the framework's styles */\r\n.form-control:focus {\r\n    box-shadow: none;\r\n    /* Removes the default bootstrap shadow on focus */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
