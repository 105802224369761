import React, { useState } from "react";
import { AddSquareIcon, TrashIcon } from "../../assets/svgs/SvgIcons"; // Import icons as needed
import "../../css/PrivateRoutes/PromptCardWrapper.css";
import ConfirmationModal from "../ConfirmationModal";
import { ToastNotifications } from "../../helpers/Enums";

const PromptCardWrapper = ({ title, children, deleteCategory }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleIconClick = () => {
    setModalOpen(true);
  };

  const handleModalConfirm = () => {
    deleteCategory(title);
    setModalOpen(false);
  };

  const handleModalCancel = () => {
    setModalOpen(false);
  };
  return (
    <div className="mt-3 prompt-card-wrapper">
      <div className="prompt-card-header">
        <span className="prompt-card-title">{title}</span>
        <div className="prompt-card-actions">
          <div className="prompt-card-action-btn" onClick={handleIconClick}>
            <TrashIcon width="20" height="20" color="red" />
          </div>
        </div>
      </div>
      <hr className="prompt-card-divider" />
      {children}
      <ConfirmationModal
        isOpen={isModalOpen}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
        message={ToastNotifications.DELETE_QUESTION()}
      />
    </div>
  );
};

export default PromptCardWrapper;
