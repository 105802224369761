import React from "react";
import "../css/InboxSectionTitle.scss";

export const InboxSectionTitle = ({ sectionTitle }) => {
  return (
    <>
      <div className="container section-title-inbox">
        <div className="py-3 pl-2 row">
          <h6>{sectionTitle}</h6>
        </div>
      </div>
    </>
  );
};
