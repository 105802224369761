// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prompts-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* This will make sure it takes full height of the parent */
}


@media (max-width: 768px) {
  .block-margin{
    display: none;
  }

}

.block-margin {
  width: 26%;
}

.category-item {
  padding: 1rem;
}

.prompt-item {
  border-radius: 8px;
  padding: 1rem;
  background: white;
  box-shadow: 1px 1px 1px 1px rgba(204, 198, 198, 0);
}`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/PromptsCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,2DAA2D;AAC7D;;;AAGA;EACE;IACE,aAAa;EACf;;AAEF;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,kDAAkD;AACpD","sourcesContent":[".prompts-card {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 100%;\r\n  /* This will make sure it takes full height of the parent */\r\n}\r\n\r\n\r\n@media (max-width: 768px) {\r\n  .block-margin{\r\n    display: none;\r\n  }\r\n\r\n}\r\n\r\n.block-margin {\r\n  width: 26%;\r\n}\r\n\r\n.category-item {\r\n  padding: 1rem;\r\n}\r\n\r\n.prompt-item {\r\n  border-radius: 8px;\r\n  padding: 1rem;\r\n  background: white;\r\n  box-shadow: 1px 1px 1px 1px rgba(204, 198, 198, 0);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
