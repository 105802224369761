import React, { createContext, useState, useCallback } from "react";

const ActiveStepContext = createContext({
  activeStep: "",
  changingStep: false,
  setActiveStep: () => {},
  setChangingStep: () => {},
});


export const ActiveStepProvider = ({ children, onStepChange }) => {
  const [activeStep, setActiveStep] = useState("");
  const [changingStep, setChangingStep] = useState(false);

  const handleSetActiveStep = useCallback(
    (newStep, values) => {
      setChangingStep(true);
      if (onStepChange) {
        onStepChange(values); // Call the save function with current values
      }
      setActiveStep(newStep);
      setChangingStep(false);
    },
    [onStepChange]
  );

  return (
    <ActiveStepContext.Provider
      value={{ activeStep, changingStep, setActiveStep: handleSetActiveStep, setChangingStep }}
    >
      {children}
    </ActiveStepContext.Provider>
  );
};

export default ActiveStepContext;
