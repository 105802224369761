import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { Footer } from "./components/Footer";
import Messageshub from "./components/Messageshub";
import { Navbar } from "./components/Navbar";
import PrivateNavbar from "./components/PrivateNavbar";
import { PrivateRoute } from "./components/PrivateRoute";
import { useAuth } from "./context/AuthContext";
import { ConversationsProvider } from "./context/ConversationsContext";
import ActiveStepContext from "./context/EditBotSteps/ActiveStepContext";
import FacebookAuth from "./pages/FacebookAuth";
import { PasswordReset } from "./pages/PasswordReset";
import { Register } from "./pages/Register";
import { Signin } from "./pages/Signin";
import { Subscribe } from "./pages/Subscribe";
import BlogComponent from "./pages/blog/BlogComponent";

import { GeneralLoader } from "./components/GeneralLoader";
import { FitPreps } from "./pages/demos/FitPreps";
import { getUserRole } from "./services/api/UserServices";
const App = () => {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [activeStep, setActiveStep] = useState("");
  const [changingStep, setChangingStep] = useState("");
  const [role, setRole] = useState(null);
  const [language, setLanguage] = useState("");


  // // Funkcja zmieniająca język
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      if (currentUser) {
        try {
          const token = await currentUser.getIdToken();
          // const fetchedRole = await getUserRole(token);
          const { role, language } = await getUserRole(token);
          console.log(language);
          setRole(role);
          setLanguage(language);
          changeLanguage(language);
        } catch (error) {
          console.error("Failed to fetch user role:", error);
        }
      }
      setLoading(false);
    };

    fetchUserRole();
  }, [currentUser]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!i18n.isInitialized) {
    return <GeneralLoader />;
  }
  const shouldHideChatbotWidget = () => {
    return location.pathname.startsWith("/demo/");
  };
  return (
    <>
      <ActiveStepContext.Provider
        value={{ activeStep, setActiveStep, changingStep, setChangingStep }}
      >
        <ConversationsProvider>
          {/* {!shouldHideChatbotWidget() && <ChatbotWidget />} */}
          <div className="wrapper">
            {loading ? <GeneralLoader /> : null}

            <div className="">
              <div className={` ${loading ? "loading" : ""}`}>
                {currentUser && location.pathname.includes("dashboard") ? (
                  <PrivateNavbar role={role} />
                ) : (
                  <>
                    {/* <TopBarAnnouncement /> */}
                    {/* {!shouldHideChatbotWidget() && <Navbar />} */}
                    <Navbar />
                    <Footer />
                  </>
                )}
                <Routes>
                  <Route path="/dutchtax" element={<FitPreps />} />
                  <Route path="/messageshub" element={<Messageshub />} />
                  <Route path="/fb" element={<FacebookAuth />} />
                  <Route path="/blog/:blogId" element={<BlogComponent />} />
                  <Route path="/subscribe" element={<Subscribe />} />
                  <Route path="/" element={<Signin />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/reset" element={<PasswordReset />} />
                  <Route
                    path="/dashboard/*"
                    element={<PrivateRoute role={role} setRole={setRole} />}
                  ></Route>
                </Routes>
              </div>
            </div>
          </div>
        </ConversationsProvider>
      </ActiveStepContext.Provider>
    </>
  );
};

export default App;
