// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-wrapper {
  background: #FFFFFF;
  min-height: 40vh;
  border-radius: 8px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  padding: 20px;
  width: auto;
  height: auto;
  overflow: hidden;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/CardWrapper.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,6CAA6C;EAC7C,aAAa;EACb,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB","sourcesContent":[".card-wrapper {\r\n  background: #FFFFFF;\r\n  min-height: 40vh;\r\n  border-radius: 8px;\r\n  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);\r\n  padding: 20px;\r\n  width: auto;\r\n  height: auto;\r\n  overflow: hidden;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
