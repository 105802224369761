import React, { useState } from "react";
import * as Yup from "yup";
import "../../css/PrivateRoutes/AddBot.css";
import NewBotForm from "../../formik/components/NewBotForm";
import { createChat } from "../../services/api/ChatService";
import { auth } from "../../firebase";
import i18n from 'i18next';

const AddBot = () => {
  const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required(i18n.t('chatbot_configuration.Bot_creation_tab.req_name')),
  });

  const initialValues = {
    employeeName: "",
  };

  const handleSubmit = async (values, actions) => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      await createChat(token, values.employeeName);
      console.log("Chatbot created successfully", values);
    } catch (error) {
      console.error("Failed to create chatbot", error);
    }
  };

  return (
    <NewBotForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    />
  );
};

export default AddBot;
