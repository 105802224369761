// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Pagination.css */
.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.pagination-controls {
    display: flex;
    align-items: center;
}

.pagination-controls span {
    margin-right: 10px;
}

.pagination-select {
    margin-right: 20px;
    padding: 5px;
}

.pagination-info {
    margin-right: 20px;
}

.pagination-button {
    margin: 0 5px;
    color: #1A3E65;
    font-size: 24px;
    /* Larger font size for bigger arrows */
    cursor: pointer;
    background: none;
    border: none;
}

.pagination-button:disabled {
    color: grey;
    cursor: default;
}

/* Add styling for hover state if needed */
.pagination-button:hover:not(:disabled) {
    color: #7a6ff0;
    /* Lighter purple shade for hover effect */
}`, "",{"version":3,"sources":["webpack://./src/css/Pagination.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,eAAe;IACf,uCAAuC;IACvC,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA,0CAA0C;AAC1C;IACI,cAAc;IACd,0CAA0C;AAC9C","sourcesContent":["/* Pagination.css */\r\n.pagination-container {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 10px 0;\r\n}\r\n\r\n.pagination-controls {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.pagination-controls span {\r\n    margin-right: 10px;\r\n}\r\n\r\n.pagination-select {\r\n    margin-right: 20px;\r\n    padding: 5px;\r\n}\r\n\r\n.pagination-info {\r\n    margin-right: 20px;\r\n}\r\n\r\n.pagination-button {\r\n    margin: 0 5px;\r\n    color: #1A3E65;\r\n    font-size: 24px;\r\n    /* Larger font size for bigger arrows */\r\n    cursor: pointer;\r\n    background: none;\r\n    border: none;\r\n}\r\n\r\n.pagination-button:disabled {\r\n    color: grey;\r\n    cursor: default;\r\n}\r\n\r\n/* Add styling for hover state if needed */\r\n.pagination-button:hover:not(:disabled) {\r\n    color: #7a6ff0;\r\n    /* Lighter purple shade for hover effect */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
