import React from "react";
import { useTranslation } from 'react-i18next';
import "../../css/PrivateRoutes/AdminDashboardCards.css";
import RobolButton from "../RobolButton";
import { useNavigate } from "react-router-dom";
import i18n from 'i18next';

const EditBotCard = ({ botName, isActive, gradient, chatbotID }) => {
  const gradientStyle = {
    background: `linear-gradient(180deg, ${gradient.start} 0%, ${gradient.end} 100%)`,
  };
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  
  return (
    <div className="edit-bot-card">
      <div className="card">
        <div className="card-img-top" style={gradientStyle}></div>
        {isActive && <div className="availability-indicator" />}
        {!isActive && <div className="not-availabile-indicator" />}
        <div className="card-body">
          <h5 className="card-title">Chatbot {botName}</h5>
          <p className="card-text">
            This is your bot <b>{botName}</b>, his speciality is sales and is
            very friendly. Main focus is <b>Customer Support</b> and problem
            solving.
          </p>
          <div className="buttons-container d-flex justify-content-around">
            <RobolButton
              onClick={() => navigate(`../admin/bot/edit/${chatbotID}`)}
              theme="dark"
            >
              {i18n.t('chatbot_configuration.ExtraDescription.btn_conf_bot_card')}
            </RobolButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBotCard;
