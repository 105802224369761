// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-overview-card-wrapper {
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/UserOverviewCardWrapper.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,6CAA6C;IAC7C,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,oBAAoB;IACpB,qBAAqB;AACzB","sourcesContent":[".user-overview-card-wrapper {\r\n    background: #FFFFFF;\r\n    border-radius: 8px;\r\n    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);\r\n    padding: 20px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    margin: 0 !important;\r\n    padding: 0 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
