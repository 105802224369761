import i18n from 'i18next';

export const GptModels = {
  GPT_35_TURBO: 1,
  GPT_35_TURBO_16k: 2,
  GPT_35_TURBO_1106: 3,
  GPT_40: 4,
  GPT_40_32k: 5,
};

export const TooltipContent = {
  PERSONALITY: () => i18n.t('chatbot_configuration.TooltipContent.PERSONALITY'),
  COMPANY: () => i18n.t('chatbot_configuration.TooltipContent.COMPANY'),
  RESTRICTIONS: () => i18n.t('chatbot_configuration.TooltipContent.RESTRICTIONS'),
  PROMPTS: () => i18n.t('chatbot_configuration.TooltipContent.PROMPTS'),
  DOCUMENT_SCRAPER: () => i18n.t('chatbot_configuration.TooltipContent.DOCUMENT_SCRAPER'),
  WEB_SCRAPER: () => i18n.t('chatbot_configuration.TooltipContent.WEB_SCRAPER'),
  LIVE_APIS: () => i18n.t('chatbot_configuration.TooltipContent.LIVE_APIS'),
  MODEL_POWER: () => i18n.t('chatbot_configuration.TooltipContent.MODEL_POWER'),
  LAYOUT: () => i18n.t('chatbot_configuration.TooltipContent.LAYOUT'),
  INTERACTIVE_TEST: () => i18n.t('chatbot_configuration.TooltipContent.INTERACTIVE_TEST'),
  WORKSCHEDULE: () => i18n.t('chatbot_configuration.TooltipContent.WORKSCHEDULE'),
  HOLIDAYS: () => i18n.t('chatbot_configuration.TooltipContent.HOLIDAYS'),
  ASK_AI: () => i18n.t('chatbot_configuration.TooltipContent.ASK_AI'),
};

export const Buttons = {
  SAVE_BTN: () => i18n.t('button_labels.SAVE'),
  DELETE_BTN: () => i18n.t('button_labels.DELETE'),
};

// TooltipContent zmienione na funkcje
// export const TooltipContent = {
//   PERSONALITY: () => i18n.t('chatbot_configuration.SidebarSubitems.TEST'),
//   TEST: () => i18n.t('chatbot_configuration.SidebarSubitems.TEST'),
//   COMPANY: () => i18n.t('chatbot_configuration.SidebarSubitems.COMPANY'),
// };

export const ForwardingStatus = {
  NOT_FORWARDED: 0,
  IS_FORWARDED: 1,
  FORWARD_REQUESTED: 2,
  REJECTED: 3,
};

export const MessagesStatus = {
  IS_NOT_READ: 0,
  IS_READ: 1,
};

export const ConversationClosed = {
  IS_OPEN: 0,
  IS_CLOSED: 1,
};

export const ToastNotifications = {
  DELETE_QUESTION: () => i18n.t('chatbot_configuration.ToastNotifications.DELETE_QUESTION'),
  YES: () => i18n.t('chatbot_configuration.ToastNotifications.YES'),
  NO: () => i18n.t('chatbot_configuration.ToastNotifications.NO'),
  AVAILABILITY_CHANGE: () => i18n.t('chatbot_configuration.ToastNotifications.AVAILABILITY_CHANGE'),
  CHANGES_SAVED: () => i18n.t('chatbot_configuration.ToastNotifications.CHANGES_SAVED'),
  DELETED: () => i18n.t('chatbot_configuration.ToastNotifications.DELETED'),
  UPLOADED: () => i18n.t('chatbot_configuration.ToastNotifications.UPLOADED'),
  SCRAPE_START: () => i18n.t('chatbot_configuration.ToastNotifications.SCRAPE_START'),
  SCRAPE_ERROR: () => i18n.t('chatbot_configuration.ToastNotifications.SCRAPE_ERROR'),
  SCRAPE_SUCCEEDED: () => i18n.t('chatbot_configuration.ToastNotifications.SCRAPE_SUCCEEDED'),
  BOT_ACTIVATED: () => i18n.t('chatbot_configuration.ToastNotifications.BOT_ACTIVATED'),
  ERROR: () => i18n.t('chatbot_configuration.ToastNotifications.ERROR'),
  NEW_REQUEST: () => i18n.t('chatbot_configuration.ToastNotifications.NEW_REQUEST'),
  CLICK_HER: () => i18n.t('chatbot_configuration.ToastNotifications.CLICK_HER'),
  TO_VIEW: () => i18n.t('chatbot_configuration.ToastNotifications.TO_VIEW'),
  BACK_POPUP: () => i18n.t('chatbot_configuration.ToastNotifications.BACK_POPUP'),
  
};



export const TablesDescription = {
  FILENAME:() => i18n.t('chatbot_configuration.TablesDescription.FILENAME'),
  UPLOAD_DATE:() => i18n.t('chatbot_configuration.TablesDescription.UPLOAD_DATE'),
  ACTIONS:() => i18n.t('chatbot_configuration.TablesDescription.ACTIONS'),
  DESCRIPTION_DOCSCRAP:() => i18n.t('chatbot_configuration.TablesDescription.DESCRIPTION_DOCSCRAP'),
  WEBSITENAME:() => i18n.t('chatbot_configuration.TablesDescription.WEBSITENAME'),
  STATUS:() => i18n.t('chatbot_configuration.TablesDescription.STATUS'),
  DESCRIPTION_WEBSCRAP:() => i18n.t('chatbot_configuration.TablesDescription.DESCRIPTION_WEBSCRAP'),
  WEB_STATUS:() => i18n.t('chatbot_configuration.TablesDescription.WEB_STATUS'),
};

export const UserRoles = {
  ADMIN: "ADMIN",
  USER: "USER",
};

//TODO: Translation
