import React, { useState } from "react";
import { InfoCircleIcon } from "../assets/svgs/SvgIcons";
import "../css/Tooltip.css";

const Tooltip = ({ toolTip }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  return (
    <div
      className="info-icon-container"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      <InfoCircleIcon
        color="#1E3050"
        width="23"
        height="23"
        className="info-icon"
      />
      {isTooltipVisible && <div className="info-tooltip">{toolTip}</div>}
    </div>
  );
};

export default Tooltip;
