import React from "react";

const FormAddBot = ({ type, onClose }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted");
    onClose(); // Close the modal on successful submission
  };

  return (
    <>
      <div className="chat_modal">
        <div className="chat_modalcontent">bot</div>
      </div>
    </>
  );
};

export default FormAddBot;
