// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-wrapper {
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
    padding: 20px;
    width: auto;
    height: auto;
    overflow: hidden;
    border: 0.3px solid white;
}

.steps-wrapper:hover {
    cursor: pointer;
    background: #FFFFFF;
    color: #1E3050;
    border: 0.3px solid #1E3050;
    /* Fix the border color */
}

.steps-wrapper.active {
    color: white;
    border: 0.3px solid #1E3050;
    /* Fix the border color */
    background-color: #1E3050;
}




`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/StepCardsWrapper.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,6CAA6C;IAC7C,aAAa;IACb,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,2BAA2B;IAC3B,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,2BAA2B;IAC3B,yBAAyB;IACzB,yBAAyB;AAC7B","sourcesContent":[".steps-wrapper {\r\n    background: #FFFFFF;\r\n    border-radius: 8px;\r\n    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);\r\n    padding: 20px;\r\n    width: auto;\r\n    height: auto;\r\n    overflow: hidden;\r\n    border: 0.3px solid white;\r\n}\r\n\r\n.steps-wrapper:hover {\r\n    cursor: pointer;\r\n    background: #FFFFFF;\r\n    color: #1E3050;\r\n    border: 0.3px solid #1E3050;\r\n    /* Fix the border color */\r\n}\r\n\r\n.steps-wrapper.active {\r\n    color: white;\r\n    border: 0.3px solid #1E3050;\r\n    /* Fix the border color */\r\n    background-color: #1E3050;\r\n}\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
