export const images = [
  {
    src: require("../assets/robot-bg.png"),
    alt: "Robot",
    title: "robot",
  },
  {
    src: require("../assets/logo.svg"),
    alt: "logo",
    title: "logo",
  },
 ];
