import React from 'react';
import "../css/PrivateRoutes/objects3loader.scss";

const Objects3Loader = ({ src, size = 'sm' }) => {
  const img = <img src={src} className="img-fluid" alt="" />;

  const ImageCol = ({ number }) => (
    <div className="col-auto">
      <div className={`object3fade object3fade--${number} object3fade--${size}`}>
        {img}
      </div>
    </div>
  );

  return (
    <div className="">
      <div className="row row--20 justify-content-center">
        <ImageCol number="first" />
        <ImageCol number="second" />
        <ImageCol number="third" />
      </div>
    </div>
  );
};

export default Objects3Loader;
