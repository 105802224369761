import React, { useEffect } from "react";
import { PencilSquare, ThreeDotsVertical, Trash } from "react-bootstrap-icons";
import "../css/ActionMenu.css";
import {
  SidebarSubitems,
  SidebarTitles,
} from "../assets/EnumSidebarConfigurator";

const ActionMenu = ({ isOpen, toggleMenu, userId, onEdit, onDelete }) => {
  // Function to close the dropdown if clicked outside
  const closeMenu = (event) => {
    if (!event.target.closest(".action-menu-container")) {
      toggleMenu(null);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", closeMenu);
    } else {
      document.removeEventListener("click", closeMenu);
    }
  }, [isOpen]); // Only re-run the effect if isOpen changes

  const handleDelete = (event) => {
    event.preventDefault(); // Prevent form submission
    onDelete(); // Call the passed onDelete function
  };

  return (
    <div className="action-menu-container">
      <ThreeDotsVertical
        onClick={() => toggleMenu(userId)}
        className="action-menu-icon"
      />
      <div
        className={`action-menu-dropdown ${
          isOpen ? "action-menu-dropdown-visible" : ""
        }`}
      >
        <button onClick={onEdit}>
          <PencilSquare className="icon" /> {SidebarSubitems.RESTRICTIONS_BTN_EDIT()}
        </button>
        <button onClick={handleDelete}>
          <Trash className="icon" /> {SidebarSubitems.RESTRICTIONS_BTN_DELETE()}
        </button>
      </div>
    </div>
  );
};

export default ActionMenu;
