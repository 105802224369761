import Compressor from "compressorjs";
import {
  Field,
  FieldArray,
  Form,
  Formik,
  FormikProvider,
  useFormik,
  useFormikContext,
} from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GLOBAL_COLORS } from "../../assets/colors";
import { TrashIcon } from "../../assets/svgs/SvgIcons";
import ConfirmationModal from "../../components/ConfirmationModal";
import { GeneralLoader } from "../../components/GeneralLoader";
import HeaderCards from "../../components/HeaderCards";
import CardWrapper from "../../components/PrivateRoutes/CardWrapper";
import FormWrapper from "../../components/PrivateRoutes/FormWrapper";
import { ProfileImageUpload } from "../../components/PrivateRoutes/ProfileImageUpload";
import RobolButton from "../../components/RobolButton";
import "../../css/PrivateRoutes/ProfileImageUpload.css";
import { auth } from "../../firebase";
import { TooltipContent } from "../../helpers/Enums";
import { stepsEmployeeForm } from "../../helpers/jsonData";
import { editUser, getUserDetails } from "../../services/api/UserServices";
import FocusError from "./FocusError";
import { FormikInput } from "./FormikInput";
import { FormikToggle } from "./FormikToggle";
import StepCards from "./StepCards";
import i18n from 'i18next';

export const EditUserForm = ({
  fields,
  toggleSwitches,
  userId,
  onSubmit,
  validationSchema,
  submitButtonText,
  newPersona,
  sideSteps,
}) => {
  const { employeeID } = useParams();
  const translatedSteps = stepsEmployeeForm.map(step => i18n.t(step));

  const [currentStep, setCurrentStep] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    role: "",
    profileImage: "",
    email: "",
    phoneNumber: "",
    stateRegion: "",
    city: "",
    address: "",
    zipCode: "",
    about: "",
    is_active: false,
    available: false,
    workingSchedule: {
      monday: { startTime: "00:00", endTime: "00:00" },
      tuesday: { startTime: "00:00", endTime: "00:00" },
      wednesday: { startTime: "00:00", endTime: "00:00" },
      thursday: { startTime: "00:00", endTime: "00:00" },
      friday: { startTime: "00:00", endTime: "00:00" },
      saturday: { startTime: "00:00", endTime: "00:00" },
      sunday: { startTime: "00:00", endTime: "00:00" },
    },
  });

  const fetchUserDetails = async () => {
    setIsLoading(true);
    const token = await auth.currentUser.getIdToken(true);
    try {
      const data = await getUserDetails(token, employeeID);
      setInitialValues(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch user details", error);
    }
  };





  useEffect(() => {
    fetchUserDetails();
  }, [employeeID]);

  const formatVacations = (vacations) => {
    return vacations.map((vacation) => ({
      fromDate: moment(vacation.fromDate).format("YYYY-MM-DD"),
      toDate: moment(vacation.toDate).format("YYYY-MM-DD"),
    }));
  };

  const handleStepChange = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const handleModalConfirm = () => {
    setModalOpen(false);
  };

  const handleModalCancel = () => {
    setModalOpen(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      console.log("values", values);
      const token = await auth.currentUser.getIdToken(true);
      try {
        await editUser(token, values, employeeID);
        onSubmit(values, actions);
        if (currentStep === stepsEmployeeForm.length - 1) {
          await fetchUserDetails();
          setCurrentStep(0);
        } else {
          handleStepChange(currentStep + 1);
        }
      } catch (error) {
        console.error("Failed to edit user", error);
      }
    },
  });

  if (isLoading) {
    return <GeneralLoader />;
  }

  const renderFormStep = ({ errors, touched }) => {
    switch (currentStep) {
      case 0:
        return (
          <FormikProvider value={formik}>
            <FormWrapper>
              <FocusError />
              <Form className="p-1">
                <div>
                  <ProfileImageUploadWrapper name="profileImage" />
                  {toggleSwitches.map((switchItem) => (
                    <FormikToggle key={switchItem.name} {...switchItem} />
                  ))}
                </div>
                <div>
                  {fields
                    .filter((field) => field.name !== "holidays") // Filtruj pole dotyczące wakacji
                    .map((field) => (
                      <div key={field.name} className="mt-3">
                        {field.type === "select" ? (
                          <div>
                            <label>{field.label}</label>
                            <Field
                              as="select"
                              name={field.name}
                              className="form-control"
                            >
                              {field.selectOptions.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Field>
                          </div>
                        ) : (
                          <div className="mt-3">
                            <label>{field.label}</label>
                            <Field
                              name={field.name}
                              type={field.type}
                              className="form-control"
                            />
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                <div className="mt-4">
                  <RobolButton
                    onClick={() => formik.submitForm()}
                    theme="dark"
                    size="m"
                    type="submit"
                  >
                    {i18n.t('chatbot_configuration.Employee_creation_tab.work_schedule_btn')}
                  </RobolButton>
                </div>
              </Form>
            </FormWrapper>
          </FormikProvider>
        );

      case 1:
        return (
          <FormikProvider value={formik}>
            <FormWrapper>
              <Form className="p-4">
                <HeaderCards
                  title={i18n.t('chatbot_configuration.Employee_creation_tab.work_schedule')}
                  tooltipContent={i18n.t('chatbot_configuration.TooltipContent.WORKSCHEDULE')} // Pass tooltip content as prop
                />
                <div className="row mb-3">
                  <div className="col-12  col-md-auto">
                    <RobolButton
                      theme="green"
                      onClick={() => {
                        const newWorkingSchedule = {
                          monday: {
                            startTime: "09:00",
                            endTime: "17:00",
                          },
                          tuesday: {
                            startTime: "09:00",
                            endTime: "17:00",
                          },
                          wednesday: {
                            startTime: "09:00",
                            endTime: "17:00",
                          },
                          thursday: {
                            startTime: "09:00",
                            endTime: "17:00",
                          },
                          friday: {
                            startTime: "09:00",
                            endTime: "17:00",
                          },
                        };
                        formik.setFieldValue(
                          "workingSchedule",
                          newWorkingSchedule
                        );
                      }}
                      className="col btn btn-primary me-2"
                    >
                      09:00 - 17:00
                    </RobolButton>
                  </div>
                  <div className="col-12 col-md-auto">
                    <RobolButton
                      theme="green"
                      onClick={() => {
                        const newWorkingSchedule = {
                          monday: {
                            startTime: "08:00",
                            endTime: "17:00",
                          },
                          tuesday: {
                            startTime: "08:00",
                            endTime: "17:00",
                          },
                          wednesday: {
                            startTime: "08:00",
                            endTime: "17:00",
                          },
                          thursday: {
                            startTime: "08:00",
                            endTime: "17:00",
                          },
                          friday: {
                            startTime: "08:00",
                            endTime: "17:00",
                          },
                        };
                        formik.setFieldValue(
                          "workingSchedule",
                          newWorkingSchedule
                        );
                      }}
                      className="col btn btn-primary"
                    >
                      08:00 - 17:00
                    </RobolButton>
                  </div>
                  <div className="col-12  col-md-auto">
                    <RobolButton
                      theme="green"
                      onClick={() => {
                        const newWorkingSchedule = {
                          monday: {
                            startTime: "09:30",
                            endTime: "17:30",
                          },
                          tuesday: {
                            startTime: "09:30",
                            endTime: "17:30",
                          },
                          wednesday: {
                            startTime: "09:30",
                            endTime: "17:30",
                          },
                          thursday: {
                            startTime: "09:30",
                            endTime: "17:30",
                          },
                          friday: {
                            startTime: "09:30",
                            endTime: "17:30",
                          },
                        };
                        formik.setFieldValue(
                          "workingSchedule",
                          newWorkingSchedule
                        );
                      }}
                      className="col btn btn-primary"
                    >
                      09:30 - 17:30
                    </RobolButton>
                  </div>
                </div>
                {["monday", "tuesday", "wednesday", "thursday", "friday"].map(
                  (day, index) => (
                    <div key={index} className="mt-3">
                      <label className="form-label">
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                      </label>
                      <div className="d-flex">
                        <Field
                          name={`workingSchedule.${day}.startTime`}
                          type="time"
                          className="form-control"
                          value={
                            formik.values.workingSchedule[day]?.startTime || ""
                          }
                          onChange={formik.handleChange}
                        />
                        <span className="m-2">t/m</span>
                        <Field
                          name={`workingSchedule.${day}.endTime`}
                          type="time"
                          className="form-control"
                          value={
                            formik.values.workingSchedule[day]?.endTime || ""
                          }
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  )
                )}
                {!Object.keys(formik.values.workingSchedule).some(
                  (day) =>
                    !!formik.values.workingSchedule[day]?.startTime &&
                    !!formik.values.workingSchedule[day]?.endTime
                ) && (
                    <div className="text-danger">
                      {/* Przynajmniej jeden dzień musi być uzupełniony. */}
                    </div>
                  )}

                <div className="mt-4">
                  <RobolButton
                    onClick={() => formik.submitForm()}
                    theme="dark"
                    size="m"
                    type="submit"
                  >
                    {i18n.t('chatbot_configuration.Employee_creation_tab.leave_schedule_btn')}
                  </RobolButton>
                </div>
              </Form>
            </FormWrapper>
          </FormikProvider>
        );

      case 2:
        return (
          <FormWrapper>
            <Formik
              initialValues={{
                ...formik.values,
                vacations: formatVacations(formik.values.vacations),
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, actions) => {
                const token = await auth.currentUser.getIdToken(true);
                try {
                  await editUser(token, values, employeeID);
                  onSubmit(values, actions);
                  if (currentStep === stepsEmployeeForm.length - 1) {
                    await fetchUserDetails();
                    setCurrentStep(0);
                  } else {
                    handleStepChange(currentStep + 1);
                  }
                } catch (error) {
                  console.error("Failed to edit user", error);
                }
              }}
            >
              {({ handleSubmit, values }) => (
                <Form className="p-4" onSubmit={handleSubmit}>
                  <FieldArray name="vacations">
                    {({ push, remove }) => (
                      <>
                        <HeaderCards
                          title={i18n.t('chatbot_configuration.Employee_creation_tab.leave')}
                          tooltipContent={i18n.t('chatbot_configuration.TooltipContent.HOLIDAYS')}
                        />
                        {values.vacations.map((vacation, index) => (
                          <>
                            <CardWrapper background={GLOBAL_COLORS.LIGHT_GREEN}>
                              <div key={index} className="row mb-3">
                                <div className="col-md-12">
                                  <label>{`${i18n.t('chatbot_configuration.Employee_creation_tab.leave')} #${index + 1}`}</label>
                                </div>
                                <div className="col-md-5">
                                  <FormikInput
                                    name={`vacations.${index}.fromDate`}
                                    type="date"
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-md-5">
                                  <FormikInput
                                    name={`vacations.${index}.toDate`}
                                    type="date"
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-md-2">
                                  <button
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    <TrashIcon
                                      width="20"
                                      height="20"
                                      color="red"
                                    />
                                  </button>
                                </div>
                              </div>
                            </CardWrapper>
                            <hr className="my-3" />
                          </>
                        ))}
                        <div className="my-3">
                          <RobolButton
                            theme="underline"
                            onClick={() => push({ fromDate: "", toDate: "" })}
                          >
                            {i18n.t('chatbot_configuration.Employee_creation_tab.add_leave')}
                          </RobolButton>
                        </div>
                        <RobolButton
                          onClick={() => handleSubmit()}
                          theme="dark"
                          size="m"
                          type="submit"
                        >
                          {i18n.t('chatbot_configuration.Employee_creation_tab.save')}
                        </RobolButton>
                      </>
                    )}
                  </FieldArray>
                  <ConfirmationModal
                    isOpen={isModalOpen}
                    onConfirm={handleModalConfirm}
                    onCancel={handleModalCancel}
                    message="Are you sure you want to delete this item?"
                  />
                </Form>
              )}
            </Formik>
          </FormWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          console.log(values);
          onSubmit(values, actions);
        }}
      >
        {({ errors, touched }) => (
          <>
            <div className="row">
              <div className="col-md-4 mb-3">
                {sideSteps && (
                  <CardWrapper>
                    <div className="user-card mb-3">
                      <StepCards
                        steps={translatedSteps}
                        onClick={handleStepChange}
                        currentStep={currentStep}
                      />
                    </div>
                  </CardWrapper>
                )}
              </div>
              <div className="col-md-8">
                <div className="row mb-3">
                  <div className="col">
                    <div className="user-card mb-3">
                      {renderFormStep({ errors, touched })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

const ProfileImageUploadWrapper = ({ name }) => {
  const { setFieldValue, values } = useFormikContext();

  const handleChange = (event) => {
    const file = event.target.files[0];

    new Compressor(file, {
      quality: 0.1, // Adjust the quality as needed
      success(result) {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = () => {
          setFieldValue(name, reader.result);
        };
      },
      error(err) {
        console.error("Compression error:", err);
      },
    });
  };

  return (
    <ProfileImageUpload
      name={name}
      handleChange={handleChange}
      profileImage={values[name]}
    />
  );
};

export default EditUserForm;
